import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Vortex } from "react-loader-spinner";
import "./PayFlow.css";

const Payflow = ({ merchants }) => {
  const navigate = useNavigate();

  const [inputError, setInputError] = useState("");
  const [amountError, setAmountError] = useState("");

  const [refnum, setRefnum] = useState("");
  const [amount, setAmount] = useState("");
  const [invoiceData, setInvoiceData] = useState("");
  const [loading, setLoading] = useState(false);

  const pay = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (refnum === "" || amount === "") {
        setInputError("Please input a reference");
        setAmountError(" Please enter an amount");
        setLoading(false);
      } else {
        const response = await axios.post("/pos", {
          requestId: "{{$guid}}",
          username: "stanbic",
          password: "password",
          merchantOrderId: "2020081014523517655",
          serviceCode: merchants.serviceCode,
          currency: "GHS",
          amount: amount,
          reference: refnum,
          url: "/webpos/createInvoice",
        });

        setInvoiceData(response.data.result);

        const invoiceNum = response.data.result.invoiceNum;
        navigate(`/checkout/${invoiceNum}`);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="payflow">
      <form className="payflow-form">
        <input
          type="number"
          placeholder={merchants.referenceField}
          className="payflow-form-input"
          value={refnum}
          onChange={(e) => {
            const value = e.target.value.slice(0, 10);
            setRefnum(value);
          }}
        />
        <div className="payflow-form-input-error">
          {!refnum && <span className="shake shake1">{inputError} </span>}
        </div>
        <input
         type="number"
          placeholder="Enter an amount"
          className="payflow-form-input"
          value={amount}
          onChange={(e) => {
            const value = e.target.value.slice(0, 5);
            if (value !== "0") {
              setAmount(value);
            }
          }}
        />
        <div className="payflow-form-input-error">
          {!amount && <span className="shake">{amountError} </span>}
        </div>

        <div className="payflow-form-total">
          <h6>
            {amount > 0 ? `GHS ${parseFloat(amount).toFixed(2)}` : "GHS 0.00"}
          </h6>
        </div>
        <button className="payflow-form-button" onClick={pay}>
          Continue To Checkout
          {loading && (
            <Vortex
              visible={true}
              height="40"
              width="40"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass="vortex-wrapper"
              colors={["white", "white", "white", "white", "white", "white"]}
            />
          )}
        </button>
      </form>
    </div>
  );
};

export default Payflow;
