

import React,{useEffect,useState} from "react";
import { Form, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams,useNavigate  } from "react-router-dom";

export default function LUPCD({ lookUp, merchants,refnum }) {
  let params = useParams();
  const navigate = useNavigate();
  const param = params.serviceCode
  const [invoiceData, setInvoiceData] = useState('');

  const pay = async (e) => {
    try {
      e.preventDefault();
      
        const dat = await axios
          .post("pos", {
            requestId: "4d55391b-f463-4df7-8918-578d8eec94b5",
            username: "stanbic",
            password: "password",
            merchantOrderId: "202008241855",
            serviceCode: param,
            currency: "GHS",
            amount: lookUp.billTotal,
            reference: refnum,
            url: "/webpos/createInvoice",
          })
          .then((res) => {
            const invoiceCreation = res.data.result;
            setInvoiceData(invoiceCreation);
            console.log(invoiceCreation, "invoice data");
            const invoiceNum=invoiceCreation.invoiceNum
            navigate(`/checkout/${invoiceNum}`, {
                state: {
                  merchant: merchants,
                  invoice: invoiceCreation,
                  refnum: refnum,
                },
              });
          })
          .catch((err) => {
            console.log(err);
          });
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    pay();
  }, []);

  return (
    <div>
      <Card className="display-container" style={{ border: "none" }}>
        <Container className="display-inner" style={{ width: "94%" }}>
          <Container className="top-display">
            <Container className="customer-name">
              <h4> Customer Name</h4>
              <h6>{ lookUp.customerName}</h6>
            </Container>
            <Container className="customer-amount">
              <h4> Amount Due</h4>
              <h6> {lookUp.billTotal}</h6>
            </Container>
          </Container>
        </Container>

        <div className="pay-total">
          <h6>Total : GHS {lookUp.billTotal}</h6>
        </div>
 

        <button
          style={{ backgroundColor: "#0fbd4f", border: "none" }}
          className="checkout-button"
          onClick={pay}
        >
          Continue To Pay
        </button>
      </Card>
    </div>
  );
}
