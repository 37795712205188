import React, { useState, useEffect } from "react";
import { IoMdArrowDropleft } from "react-icons/io";
import { Input, Button, Form } from "antd";
import CheckoutOptions from "../../../CheckoutOptions/CheckoutOptions";
import Validation from "../../../Validation/Validation";
import axios from "axios";
import "./MobileMoney.css";

const MobileMoney = ({ invoiceData, momos, setCurrent }) => {
  const [loading, setLoading] = useState(false);
  const [previous, setPrevious] = useState(false);
  const [pay, setPay] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [processPayment, setProcessPayment] = useState({});

  const previousPage = (e) => {
    e.preventDefault();
    setPrevious(true);
    setCurrent(0);
  };

  const makePayment = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setPay(true);
      const ProcessPaymentResponse = await axios.post("/pos", {
        username: "stanbic",
        password: "password",
        requestId: "{{$guid}}",
        invoiceNum: invoiceData.invoiceNum,
        transactionId: "{{transId}}",
        provider: "VODAFONE_CASH",
        walletRef: "0203465176",
        customerName: firstName,
        customerMobile: "0203465176",
        url: "/webpos/processPayment",
      });

      setProcessPayment(ProcessPaymentResponse?.data);
      setLoading(false);
      window.localStorage.removeItem("formData");
    } catch (error) {
      setPay(false);
    }
  };
  console.log(phoneNumber, "momo");

  useEffect(() => {
    const formData = window.localStorage.getItem("formData");
    if (formData) {
      const savedValues = JSON.parse(formData);
      setFirstName(savedValues.firstName);
      setLastName(savedValues.lastName);
      setPhoneNumber(savedValues.phoneNumber);
    }
  }, []);

  useEffect(() => {
    const valuesToSave = { firstName, lastName, phoneNumber };
    window.localStorage.setItem("formData", JSON.stringify(valuesToSave));
  }, [firstName, lastName, phoneNumber]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      window.localStorage.removeItem("formData");
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        window.localStorage.removeItem("formData");
      });
    };
  }, []);

  return (
    <div>
      {previous ? (
        <CheckoutOptions invoiceData={invoiceData} setCurrent={setCurrent} />
      ) : (
        <>
          {pay ? (
            <Validation
              invoiceData={invoiceData}
              phoneNumber={phoneNumber}
              processPayment={processPayment}
              momos={momos}
              setCurrent={setCurrent}
            />
          ) : (
            <div className="m-m">
              <div className="mobilemoney">
                <div className="momo-header">
                  <div className="momo-header-img">
                    <img src={momos.logo}></img>
                  </div>
                  <div className="momo-header-text">
                    <p className="font1">{momos.name}</p>
                    <p className="font2">{momos.type.replaceAll("_", "  ")}</p>
                  </div>
                </div>

                {/* ...................... */}
                <div className="momo">
                  <Form className="momo-form-name" layout="vertical">
                    <div className="momo-form-name-first">
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your first name",
                          },
                        ]}
                        style={{ marginBottom: "0" }}
                      >
                        <Input
                          className="momo-form-input"
                          type="text"
                          pattern="[A-Za-z]"
                          placeholder="eg. John"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Form.Item>
                    </div>

                    <div className="momo-form-name-last">
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your last name",
                          },
                        ]}
                        style={{ marginBottom: "0" }}
                      >
                        <Input
                          className="momo-form-input"
                          type="text"
                          placeholder="eg. Doe"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  </Form>

                  <Form className="momo-form-phone" layout="vertical">
                    <Form.Item
                      label="Phone Number"
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number",
                        },
                      ]}
                      style={{ marginBottom: "0" }}
                    >
                      <Input
                        className="momo-form-input1"
                        type="number"
                        placeholder="eg. 0557293485"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Form.Item>
                  </Form>
                  <div className="centerfield">
                    <div className="cap">ReCaptcha</div>
                  </div>
                </div>
                {/* ........................ */}
              </div>
              <div className="checkoutInput-buttons">
                <a onClick={previousPage}>
                  <IoMdArrowDropleft className="icon" />
                  <span>Back to payment option</span>
                </a>
                <button onClick={makePayment}> PAY </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MobileMoney;
