import React from 'react';
import './Navbar.css';
import KB from '../../images/kowribus.png';

const Navbar = () => {
  const navbar = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('/vehicle') || currentUrl.includes('/circles') || currentUrl.includes('/travel')) {
      return null;
    } else {
      return (
        <div className='kowri-navbar'>
          <div className='kowri-navbar-img'>
            <img src={KB} alt='KB' />
          </div>
        </div>
      );
    }
  };

  return <>{navbar()}</>;
};

export default Navbar;
