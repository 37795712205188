import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { date } from "../../../utils/dateformat";
import { CopyOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import "../../MotorInsurance/PayPremium/PayPremium.css";

const TravelPayPremium = () => {
  const [loading, setLoading] = useState(false);
  const [underwriting, setUnderwriting] = useState("");
  const [customer, setCustomer] = useState("");
  const [isDetailsVisible, setDetailsVisible] = useState(false);

  const { serviceCode } = useParams();
  const navigate = useNavigate();

  const storedCustomerDetails = localStorage.getItem("customerDetails");
  const customerDetails = JSON.parse(storedCustomerDetails);

  const storedTravelDetails = localStorage.getItem("travelDetails");
  const travelDetails = JSON.parse(storedTravelDetails);

  const storedcustomerCode = localStorage.getItem("customerCode");
  const customerCode = JSON.parse(storedcustomerCode);

  const storedTravelPremiumDetails = localStorage.getItem(
    "travelPremiumDetails"
  );
  const travelPremiumDetails = JSON.parse(storedTravelPremiumDetails);

  const generateTravelPremiumDetails =
    travelPremiumDetails?.travelPremium?.data;

  const storedTravelPolicyDetails = localStorage.getItem("travelPolicyDetails");
  const travelPolicyDetails = JSON.parse(storedTravelPolicyDetails);

  const preMedicalConditionYn = travelPolicyDetails?.isMedicalChecked
    ? "Y"
    : "N";
  const declarationYn = travelPolicyDetails?.isDeclarationChecked ? "Y" : "N";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let uuid = uuidv4();
      try {
        let finalCustomerCode = customerCode;
        if (!customerCode) {
          const customerResponse = await axios.post("/star", {
            requestId: uuid,
            serviceCode: serviceCode,
            method: "createCustomer",
            async: true,
            params: {
              firstName: customerDetails?.firstName,
              lastName: customerDetails?.lastName,
              PhoneNumber: customerDetails?.PhoneNumber,
              email: customerDetails?.email,
              dateOfBirth: customerDetails?.dateOfBirth,
              identificationNumber: customerDetails?.identificationNumber,
              identificationType: customerDetails?.identificationType,
              gender: customerDetails?.gender,
            },
            url: "/switching/process",
          });

          setCustomer(customerResponse.data.result);
          const customerCodeDetail = {
            customerCode: customerResponse?.data?.result?.data?.code,
          };
          localStorage.removeItem("customerCodeDetail");
          localStorage.setItem(
            "customerCodeDetail",
            JSON.stringify(customerCodeDetail)
          );
          finalCustomerCode = customerCodeDetail?.customerCode;
        }

        const underwritingResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "travelPolicyUnderwriting",
          async: true,
          params: {
            customerCode: finalCustomerCode,
            intermediaryCode: "",
            startDate: travelDetails?.travelDate,
            endDate: travelDetails?.returnDate,
            policyDays: travelDetails?.days.toString(),
            underwritingYear: underwritingYear.toString(),
            sumInsured:
              generateTravelPremiumDetails?.TotalSumInsured.toString(),
            premium: generateTravelPremiumDetails?.TotalPremium.toString(),
            state: "S",
            policyNumber: "",
            policyType: "1",
            status: "U",
            classCode: "6000",
            customerName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
            customerAddress: travelPolicyDetails?.address,
            customerPhoneNumber: customerDetails?.PhoneNumber,
            premiumBasis: "FP",
            coverage: travelDetails?.coverage,
            embassyAddress: travelPolicyDetails?.embassyAddress,
            travelRiskStartDate: travelDetails?.travelDate,
            travelRiskEndDate: travelDetails?.returnDate,
            periodDays: travelDetails?.days.toString(),
            travelRiskUnderwritingYear: underwritingYear.toString(),
            fullName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
            dateOfBirth: customerDetails?.dateOfBirth,
            age: travelDetails?.age.toString(),
            gender: customerDetails?.gender,
            nationality: travelPolicyDetails?.nationalityName,
            nationalId: customerDetails?.identificationNumber,
            passportExpiryDate: travelPolicyDetails?.expiryDate,
            destinationAddress: travelDetails?.destinationAddress,
            address: travelPolicyDetails?.address,
            destinationPhone: travelDetails?.destinationNumber,
            beneficiaryName: travelPolicyDetails?.beneficiaryName,
            beneficiaryRelation: travelPolicyDetails?.beneficiaryRelationship,
            beneficiaryPhone: travelPolicyDetails?.beneficiaryNumber,
            passportNumber: travelPolicyDetails?.passportNumber,
            occupation: "",
            issuingCountryCode: travelPolicyDetails?.issuingCountryCode,
            issuingCountryName: travelPolicyDetails?.issuingCountryName,
            countryCode: travelPolicyDetails?.issuingCountryCode,
            preMedicalConditionYn: preMedicalConditionYn,
            preMedicalConditionDetails: travelPolicyDetails?.medicalDetails,
            declarationYn: declarationYn,
            declarationDetails: travelPolicyDetails?.declarationDetails,
            days: travelDetails?.days.toString(),
            travelRiskCurrencyRate: "1",
            travelRiskCurrency: generateTravelPremiumDetails?.currency,
            riskPremium: generateTravelPremiumDetails?.TotalPremium.toString(),
            travelRiskCreatedBy: "KOWRI",
            createdDate: formattedDate,
          },
          url: "/switching/process",
        });

        setUnderwriting(underwritingResponse.data.result);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const underwritingDetails = underwriting?.data;
  const year = new Date(travelDetails?.travelDate);
  const underwritingYear = year.getUTCFullYear();
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const thisyear = today.getFullYear();
  const formattedDate = `${thisyear}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const handleCustomerRetry = async () => {
    setLoading(true);
    let uuid = uuidv4();
    try {
      let finalCustomerCode = customerCode;
      if (!customerCode) {
        const customerResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "createCustomer",
          async: true,
          params: {
            firstName: customerDetails?.firstName,
            lastName: customerDetails?.lastName,
            PhoneNumber: customerDetails?.PhoneNumber,
            email: customerDetails?.email,
            dateOfBirth: customerDetails?.dateOfBirth,
            identificationNumber: customerDetails?.identificationNumber,
            identificationType: customerDetails?.identificationType,
            gender: customerDetails?.gender,
          },
          url: "/switching/process",
        });

        setCustomer(customerResponse.data.result);
        const customerCodeDetail = {
          customerCode: customerResponse?.data?.result?.data?.code,
        };
        localStorage.removeItem("customerCodeDetail");
        localStorage.setItem(
          "customerCodeDetail",
          JSON.stringify(customerCodeDetail)
        );
        finalCustomerCode = customerCodeDetail?.customerCode;
      }
      const underwritingResponse = await axios.post("/star", {
        requestId: uuid,
        serviceCode: serviceCode,
        method: "travelPolicyUnderwriting",
        async: true,
        params: {
          customerCode: finalCustomerCode,
          intermediaryCode: "",
          startDate: travelDetails?.travelDate,
          endDate: travelDetails?.returnDate,
          policyDays: travelDetails?.days.toString(),
          underwritingYear: underwritingYear.toString(),
          sumInsured: generateTravelPremiumDetails?.TotalSumInsured.toString(),
          premium: generateTravelPremiumDetails?.TotalPremium.toString(),
          state: "S",
          policyNumber: "",
          policyType: "1",
          status: "U",
          classCode: "6000",
          customerName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
          customerAddress: travelPolicyDetails?.address,
          customerPhoneNumber: customerDetails?.PhoneNumber,
          premiumBasis: "FP",
          coverage: travelDetails?.coverage,
          embassyAddress: travelPolicyDetails?.embassyAddress,
          travelRiskStartDate: travelDetails?.travelDate,
          travelRiskEndDate: travelDetails?.returnDate,
          periodDays: travelDetails?.days.toString(),
          travelRiskUnderwritingYear: underwritingYear.toString(),
          fullName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
          dateOfBirth: customerDetails?.dateOfBirth,
          age: travelDetails?.age.toString(),
          gender: customerDetails?.gender,
          nationality: travelPolicyDetails?.nationalityName,
          nationalId: customerDetails?.identificationNumber,
          passportExpiryDate: travelPolicyDetails?.expiryDate,
          destinationAddress: travelDetails?.destinationAddress,
          address: travelPolicyDetails?.address,
          destinationPhone: travelDetails?.destinationNumber,
          beneficiaryName: travelPolicyDetails?.beneficiaryName,
          beneficiaryRelation: travelPolicyDetails?.beneficiaryRelationship,
          beneficiaryPhone: travelPolicyDetails?.beneficiaryNumber,
          passportNumber: travelPolicyDetails?.passportNumber,
          occupation: "",
          issuingCountryCode: travelPolicyDetails?.issuingCountryCode,
          issuingCountryName: travelPolicyDetails?.issuingCountryName,
          countryCode: travelPolicyDetails?.issuingCountryCode,
          preMedicalConditionYn: preMedicalConditionYn,
          preMedicalConditionDetails: travelPolicyDetails?.medicalDetails,
          declarationYn: declarationYn,
          declarationDetails: travelPolicyDetails?.declarationDetails,
          days: travelDetails?.days.toString(),
          travelRiskCurrencyRate: "1",
          travelRiskCurrency: generateTravelPremiumDetails?.currency,
          riskPremium: generateTravelPremiumDetails?.TotalPremium.toString(),
          travelRiskCreatedBy: "KOWRI",
          createdDate: formattedDate,
        },
        url: "/switching/process",
      });

      setUnderwriting(underwritingResponse.data.result);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const storedcustomerCodeDetail = localStorage.getItem("customerCodeDetail");
  const customerCodeDetail = JSON.parse(storedcustomerCodeDetail);

  const handleUnderwritingRetry = async () => {
    setLoading(true);
    let uuid = uuidv4();
    try {
      const underwritingResponse = await axios.post("/star", {
        requestId: uuid,
        serviceCode: serviceCode,
        method: "travelPolicyUnderwriting",
        async: true,
        params: {
          customerCode: customerCode
            ? customerCode
            : customerCodeDetail?.customerCode,
          intermediaryCode: "",
          startDate: travelDetails?.travelDate,
          endDate: travelDetails?.returnDate,
          policyDays: travelDetails?.days.toString(),
          underwritingYear: underwritingYear.toString(),
          sumInsured: generateTravelPremiumDetails?.TotalSumInsured.toString(),
          premium: generateTravelPremiumDetails?.TotalPremium.toString(),
          state: "S",
          policyNumber: "",
          policyType: "1",
          status: "U",
          classCode: "6000",
          customerName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
          customerAddress: travelPolicyDetails?.address,
          customerPhoneNumber: customerDetails?.PhoneNumber,
          premiumBasis: "FP",
          coverage: travelDetails?.coverage,
          embassyAddress: travelPolicyDetails?.embassyAddress,
          travelRiskStartDate: travelDetails?.travelDate,
          travelRiskEndDate: travelDetails?.returnDate,
          periodDays: travelDetails?.days.toString(),
          travelRiskUnderwritingYear: underwritingYear.toString(),
          fullName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
          dateOfBirth: customerDetails?.dateOfBirth,
          age: travelDetails?.age.toString(),
          gender: customerDetails?.gender,
          nationality: travelPolicyDetails?.nationalityName,
          nationalId: customerDetails?.identificationNumber,
          passportExpiryDate: travelPolicyDetails?.expiryDate,
          destinationAddress: travelDetails?.destinationAddress,
          address: travelPolicyDetails?.address,
          destinationPhone: travelDetails?.destinationNumber,
          beneficiaryName: travelPolicyDetails?.beneficiaryName,
          beneficiaryRelation: travelPolicyDetails?.beneficiaryRelationship,
          beneficiaryPhone: travelPolicyDetails?.beneficiaryNumber,
          passportNumber: travelPolicyDetails?.passportNumber,
          occupation: "",
          issuingCountryCode: travelPolicyDetails?.issuingCountryCode,
          issuingCountryName: travelPolicyDetails?.issuingCountryName,
          countryCode: travelPolicyDetails?.issuingCountryCode,
          preMedicalConditionYn: preMedicalConditionYn,
          preMedicalConditionDetails: travelPolicyDetails?.medicalDetails,
          declarationYn: declarationYn,
          declarationDetails: travelPolicyDetails?.declarationDetails,
          days: travelDetails?.days.toString(),
          travelRiskCurrencyRate: "1",
          travelRiskCurrency: generateTravelPremiumDetails?.currency,
          riskPremium: generateTravelPremiumDetails?.TotalPremium.toString(),
          travelRiskCreatedBy: "KOWRI",
          createdDate: formattedDate,
        },
        url: "/switching/process",
      });

      setUnderwriting(underwritingResponse.data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/redirect/${serviceCode}/error`);
  };
  const handleBackClick = () => {
    navigate(-1);
  };

  const handleClick = () => {
    localStorage.clear();

    const paymentDetails = {
      paymentPin: underwritingDetails?.paymentPin,
    };

    const queryParams = new URLSearchParams(paymentDetails).toString();
    navigate(`/redirect/${serviceCode}/success?${queryParams}`);
  };
  const [isCopied, setIsCopied] = useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const toggleDetailsVisibility = () => {
    setDetailsVisible(!isDetailsVisible);
  };
  return (
    <>
      {loading ? (
        <div className="jsonForm-loader">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="MotorInsurance">
          <div className="wizard">
            <div className="wizardComponent">
              <div className="TravelDetails" style={{ color: "#000" }}>
                <FormattedMessage id="component.travel.travelDetails" />
              </div>
              <div className="CustomerDetails" style={{ color: "#000" }}>
                <FormattedMessage id="component.travel.policyDetails" />
              </div>
              <div className="Premium2" style={{ color: "#000" }}>
                <FormattedMessage id="component.getQuote" />
              </div>
              <div className="Payment" style={{ color: "#000" }}>
                <FormattedMessage id="component.payment" />
              </div>
              <div
                className="Rectangle1"
                style={{ background: "#40AD52" }}
              ></div>
              <div
                className="Rectangle2"
                style={{ background: "#40AD52" }}
              ></div>
              <div
                className="Rectangle3"
                style={{ background: "#40AD52" }}
              ></div>
              <div className="Ellipse">
                <div
                  className="EllipsePremium"
                  style={{ background: "#40AD52" }}
                ></div>
                <div
                  className="EllipseCustomerDetails"
                  style={{ background: "#40AD52" }}
                ></div>
                <div
                  className="EllipsePayment"
                  style={{ background: "#40AD52" }}
                ></div>
                <div
                  className="EllipseTravelDetails"
                  style={{ background: "#40AD52" }}
                ></div>
              </div>
            </div>
          </div>

          <div className="MotorInsuranceTitle">
            <h3>
              <FormattedMessage id="component.payment" />
            </h3>
          </div>
          {(customer || customerCode || customerCodeDetail) && underwriting ? (
            <>
              <div className="PayPremium">
                <div className="PayPremium-card">
                  <div>
                    <h5>{underwritingDetails?.policyNumber}</h5>
                    <h6>
                      <FormattedMessage id="component.policyNumber" />
                    </h6>
                  </div>

                  <div>
                    <div className="premium-row">
                      <p className="premium-col1">
                        <FormattedMessage id="component.paymentPin" />
                      </p>
                      <div className="premium-col1-copy">
                        <p className="premium-col2">
                          {underwritingDetails?.paymentPin
                            ? underwritingDetails?.paymentPin
                            : "N/A"}
                        </p>
                        <span>
                          <CopyToClipboard
                            text={underwritingDetails?.paymentPin}
                            onCopy={onCopyText}
                          >
                            <span>
                              {isCopied ? (
                                "Copied!"
                              ) : (
                                <CopyOutlined className="Copy" />
                              )}
                            </span>
                          </CopyToClipboard>
                        </span>
                      </div>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1">
                        <FormattedMessage id="component.travel.destination" />
                      </p>
                      <p className="premium-col2">
                        {underwritingDetails?.travelRisks[0]?.countries[0]?.name
                          ? underwritingDetails?.travelRisks[0]?.countries[0]
                              ?.name
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1">
                        <FormattedMessage id="component.travel.passportNumber" />
                      </p>
                      <p className="premium-col2">
                        {underwritingDetails?.travelRisks[0]?.passportNumber
                          ? underwritingDetails?.travelRisks[0]?.passportNumber
                          : "N/A"}
                      </p>
                    </div>
                    {isDetailsVisible && (
                      <>
                        <div className="premium-row">
                          <p className="premium-col1">
                            <FormattedMessage id="component.customerCode" />
                          </p>
                          <p className="premium-col2">
                            {underwritingDetails?.customerCode
                              ? underwritingDetails?.customerCode
                              : "N/A"}
                          </p>
                        </div>
                        <div className="premium-row">
                          <p className="premium-col1">
                            <FormattedMessage id="component.startDate" />
                          </p>
                          <p className="premium-col2">
                            {date(underwritingDetails?.startDate)
                              ? date(underwritingDetails?.startDate)
                              : "N/A"}
                          </p>
                        </div>
                        <div className="premium-row">
                          <p className="premium-col1">
                            <FormattedMessage id="component.endDate" />
                          </p>
                          <p className="premium-col2">
                            {date(underwritingDetails?.endDate)
                              ? date(underwritingDetails?.endDate)
                              : "N/A"}
                          </p>
                        </div>
                        <div className="premium-row">
                          <p className="premium-col1">
                            <FormattedMessage id="component.duration" />
                          </p>
                          <p className="premium-col2">
                            {underwritingDetails?.policyDays
                              ? underwritingDetails?.policyDays
                              : "N/A"}
                          </p>
                        </div>
                        <div className="premium-row">
                          <p className="premium-col1">
                            <FormattedMessage id="component.underwritingYear" />
                          </p>
                          <p className="premium-col2">
                            {underwritingDetails?.underwritingYear
                              ? underwritingDetails?.underwritingYear
                              : "N/A"}
                          </p>
                        </div>
                        <div className="premium-row">
                          <p className="premium-col1">
                            <FormattedMessage id="component.travel.expiryDate" />
                          </p>
                          <p className="premium-col2">
                            {date(
                              underwritingDetails?.travelRisks[0]
                                ?.passportExpiryDate
                            )
                              ? date(
                                  underwritingDetails?.travelRisks[0]
                                    ?.passportExpiryDate
                                )
                              : "N/A"}
                          </p>
                        </div>

                        <div className="premium-row">
                          <p className="premium-col1">
                            <FormattedMessage id="component.travel.currency" />
                          </p>
                          <p className="premium-col2">
                            {underwritingDetails?.travelRisks[0]?.currency
                              ? underwritingDetails?.travelRisks[0]?.currency
                              : "N/A"}
                          </p>
                        </div>
                        <div className="premium-row">
                          <p className="premium-col1">
                            <FormattedMessage id="component.travel.currencyRate" />
                          </p>
                          <p className="premium-col2">
                            {underwritingDetails?.travelRisks[0]?.currencyRate
                              ? underwritingDetails?.travelRisks[0]
                                  ?.currencyRate
                              : "N/A"}
                          </p>
                        </div>
                        <div className="premium-row">
                          <p className="premium-col1">
                            <FormattedMessage id="component.premium" />
                          </p>
                          <p className="premium-col2">
                            GHS
                            {underwritingDetails?.premium
                              ? underwritingDetails?.premium
                              : "N/A"}
                          </p>
                        </div>
                        <div className="premium-row">
                          <p className="premium-col1">
                            <FormattedMessage id="component.sumInsured" />
                          </p>
                          <p className="premium-col2">
                            GHS
                            {underwritingDetails?.sumInsured
                              ? underwritingDetails?.sumInsured
                              : "0"}
                          </p>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="collaps-btn">
                    <button onClick={toggleDetailsVisibility}>
                      {isDetailsVisible ? (
                        <FaChevronCircleUp />
                      ) : (
                        <FaChevronCircleDown />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="button">
                <div className="button-double">
                  <button className="button-gray" onClick={handleBackClick}>
                    <FormattedMessage id="component.button.back" />
                  </button>
                  <button
                    className="button-green"
                    onClick={() => handleClick()}
                  >
                    <FormattedMessage id="component.button.next" />
                  </button>
                </div>
              </div>
            </>
          ) : !customer && !customerCode && !customerCodeDetail ? (
            <div className="modal">
              <div className="modal-content">
                <h3>
                  <FormattedMessage id="component.premiumCreationFailed" />
                </h3>
                <p>
                  <FormattedMessage id="component.PolicyCreationFailed.message" />
                </p>
                <div>
                  <button
                    className="button-modal-green"
                    onClick={handleCustomerRetry}
                  >
                    <FormattedMessage id="component.button.retry" />
                  </button>
                  <button className="button-modal-gray" onClick={handleCancel}>
                    <FormattedMessage id="component.button.cancel" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal">
              <div className="modal-content">
                <h3>
                  <FormattedMessage id="component.premiumCreationFailed" />
                </h3>
                <p>
                  <FormattedMessage id="component.PolicyCreationFailed.message" />
                </p>
                <div>
                  <button
                    className="button-modal-green"
                    onClick={handleUnderwritingRetry}
                  >
                    <FormattedMessage id="component.button.retry" />
                  </button>
                  <button className="button-modal-gray" onClick={handleCancel}>
                    <FormattedMessage id="component.button.cancel" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TravelPayPremium;
