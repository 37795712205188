
import React from "react";
import "./Home.css";
import logo from "../../images/Kowri Logo-01 (1).svg";

const Home = () => {
  return (
    <div className="kowri-home">
      <h1 className="kowri-home-h1">Welcome To</h1>
      <div className="kowri-home-img">
        <img src={logo} />
      </div>
    </div>
  );
};

export default Home;
