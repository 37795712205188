
import { useState } from "react";
import { IoMdArrowDropleft } from "react-icons/io";
import Card from "./Card";

import "./CardSheet.css";

const CardSheet = ({ momos,invoiceData,invoice,setCurrent }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [next, setNext] = useState(false);

  const nextPage = (e) => {
    e.preventDefault();
    setNext(!next);
  };
  return (
    <div>
      {next ? (
        <Card
          momos={momos}
          invoiceData={invoiceData}
          invoice={invoice}
          setCurrent={setCurrent}
        />
      ) : (
        <div className="cardsheet">
          <div className="cardsheet-card">
            <div className="cardsheet-card-form">
              <div className="mobilemoney">
                <div className="momo-header">
                  <div className="momo-header-img">
                    <img src={momos.logo}></img>
                  </div>
                  <div className="momo-header-text">
                    <p className="font1">{momos.name}</p>
                    <p className="font2">{momos.type.replaceAll("_", "  ")}</p>
                  </div>
                </div>
              </div>

              <div className="cardsheet-card-form-content">
                <form>
                  <div className="cardsheet-form-phone">
                    <label className="bank-form-input-label">Email</label>
                    <input
                      className="card-form-input1"
                      type="email"
                      placeholder="eg. Johndoe@example.com"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="cardsheet-form-phone">
                    <label className="bank-form-input-label">
                      Phone Number
                    </label>
                    <input
                      className="card-form-input1"
                      type="text"
                      placeholder="eg. 0200000000"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="cardsheet-form-phone">
                    <label className="bank-form-input-label">Card Number</label>
                    <input
                      className="card-form-input1"
                      type="text"
                      placeholder="eg. 422220000000000"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="cardsheet-form-phone">
                    <label className="bank-form-input-label">
                      Name on Card
                    </label>
                    <input
                      className="card-form-input1"
                      type="text"
                      placeholder="eg. John Doe"
                      pattern="[0-9]{10}"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="cardsheet-form-name">
                    <div className="bank-form-name-first">
                      <label className="bank-form-input-label">Exp Date</label>
                      <input
                        className="card-form-input"
                        type="text"
                        placeholder="eg. 09/23"
                        required
                        autofocus
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>

                    <div className="bank-form-name-last">
                      <label className="bank-form-input-lable">CVV</label>
                      <input
                        className="card-form-input"
                        type="text"
                        placeholder="eg. 636"
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>

                </form>
                <a onClick={nextPage} className='cardsheet-back'>
                  <IoMdArrowDropleft className="icon" />
                  <span>Back</span>
                </a>
              </div>
            </div>

            <div className="cardsheet-summary">
              <div className="cardsheet-summary-items">
                <div className="cardsheet-card-summary-items-header">
                  Order Summary
                </div>
                <div className="cardsheet-card-summary-items-content">
                  <div className="cardsheet-card-summary-items-content-1">
                    <p>Paying</p>
                    <p>MTN Airtime</p>
                  </div>

                  <div className="cardsheet-card-summary-items-content-1">
                    <p>Amount</p>
                    <p>12.00</p>
                  </div>
                  <div className="cardsheet-card-summary-items-content-1">
                    <p>Reference</p>
                    <p>huo51lnjhv</p>
                  </div>
                </div>
              </div>

              <div className="cardsheet-ad">
                <div className="cardsheet-image">
                  <img src="" alt="logo" />
                </div>
                <button className="cardsheet-button">PAY</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardSheet;
