
import React, { useState, useEffect } from "react";
import { IoMdArrowDropleft } from "react-icons/io";
import CheckoutOptions from "../../../CheckoutOptions/CheckoutOptions";
import Verify from "../../../Verify/Verify";
import CardSheet from "./CardSheet";
import axios from "axios";
import "./Card.css";

const Card = ({ invoice, invoiceData, momos, setCurrent }) => {
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [nextt, setNextt] = useState(false);
  const [pay, setPay] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [processPayment, setProcessPayment] = useState("");
  const [errors, setErrors] = useState();
  const [holdOn, setHoldOn] = useState(false);

  const makePayment = async (e) => {
    try {
      e.preventDefault();
      setCurrent(1);
      setHoldOn(true);
      setPay(true);
      if (
        firstName === "" ||
        lastName === "" ||
        phoneNumber === "" ||
        email === ""
      ) {
        setPhoneNumberError("Please input a phone number");
        setLastNameError(" Please enter your last name");
        setFirstNameError(" Please enter your first name");
        setEmailError(" Please enter your email address");
        setHoldOn(false);
        setPay(false);
      } else {
        const dat = await axios
          .post("pos", {
            username: "stanbic",
            password: "password",
            requestId: "{{$guid}}",
            invoiceNum: invoice.invoiceNum,
            transactionId: "{{transId}}",
            provider: momos.provider,
            walletRef: phoneNumber,
            customerName: firstName,
            customerMobile: phoneNumber,
            url: "/webpos/processPayment",
          })
          .then((res) => {
            const invoiceGot = res.data;
            setProcessPayment(invoiceGot);
            console.log(processPayment, "paymentInfo");
          });
        setHoldOn(false);
        setPay(true);
      }
    } catch (error) {
      console.log();
    }
  };

  const nextPage = (e) => {
    e.preventDefault();
    setNextt(!nextt);
    setCurrent(0);
  };

  useEffect(() => {
    makePayment();
    const formData = window.localStorage.getItem("formData");
    if (formData) {
      const savedValues = JSON.parse(formData);
      setFirstName(savedValues.firstName);
      setLastName(savedValues.lastName);
      setPhoneNumber(savedValues.phoneNumber);
       setEmail(savedValues.email);
    }
  }, []);

  useEffect(() => {
    const valuesToSave = { firstName, lastName, phoneNumber, email };

    window.localStorage.setItem("formData", JSON.stringify(valuesToSave));
  }, [firstName, lastName, phoneNumber, email]);

  return (
    <div>
      {nextt ? (
        <CheckoutOptions
          invoiceData={invoiceData}
          invoice={invoice}
          setCurrent={setCurrent}
          momos={momos}
        />
      ) : (
        <>
          {pay ? (
            // <Verify
            //   invoiceData={invoiceData}
            //   phoneNumber={phoneNumber}
            //   errors={errors}
            //   processPayment={processPayment}
            //   holdOn={holdOn}
            //   momos={momos}
            //   setCurrent={setCurrent}
            // />
            <CardSheet
              momos={momos}
              invoiceData={invoiceData}
              invoice={invoice}
              setCurrent={setCurrent}
            />
          ) : (
            <div className="mobilemoney">
              <div className="momo-header">
                <div className="momo-header-img">
                  <img src={momos.logo}></img>
                </div>
                <div className="momo-header-text">
                  <p className="font1">{momos.name}</p>
                  <p className="font2">{momos.type}</p>
                </div>
              </div>

              {/* ...................... */}
              <div className="card">
                <form className="card-form-name">
                  <div className="card-form-name-first">
                    <label className="card-form-input-label">First Name</label>
                    <input
                      className="card-form-input"
                      type="text"
                      placeholder="eg. John"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <div className="payflow-form-input-error">
                      {!firstName && (
                        <span className="shake">{firstNameError} </span>
                      )}
                    </div>
                  </div>

                  <div className="card-form-name-last">
                    <label className="card-form-input-lable">Last Name</label>
                    <input
                      className="card-form-input"
                      type="text"
                      placeholder="eg. Doe"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <div className="payflow-form-input-error">
                      {!lastName && (
                        <span className="shake">{lastNameError} </span>
                      )}
                    </div>
                  </div>
                </form>
                <form className="card-form-phone">
                  <label className="card-form-input-label">Phone Number</label>
                  <input
                    className="card-form-input1"
                    type="text"
                    placeholder="eg. 0557293485"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <div className="payflow-form-input-error">
                    {!phoneNumber && (
                      <span className="shake">{phoneNumberError} </span>
                    )}
                  </div>
                </form>
                <form className="card-form-phone">
                  <label className="card-form-input-label">Email</label>
                  <input
                    className="card-form-input1"
                    type="email"
                    placeholder="eg. Johndoe@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="payflow-form-input-error">
                    {!email && <span className="shake">{emailError}</span>}
                  </div>
                </form>
                <div className="cap">ReCaptcha</div>
              </div>
              {/* ........................ */}

              <div className="checkoutInput-buttons">
                <a onClick={nextPage}>
                  <IoMdArrowDropleft className="icon" />
                  <span>Back to payment option</span>
                </a>
                <button onClick={makePayment}> Next</button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Card;
