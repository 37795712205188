import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LUP, LUPI, LUPO, LUPA, PayFlow, PayOptions } from "../../components";
import { LineWave} from "react-loader-spinner";
import axios from "axios";
import "./Main.css";

const Main = () => {
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(false);
  let params = useParams();
  const param = params.serviceCode;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post("/core", {
          serviceCode: param,
          verbose: true,
          url: "/billing/collection",
        });
  
        setMerchants(response.data.data);
  
        setLoading(true);
      } catch (error) {
        
      }
    };
  
    fetchData();
  }, []);
  
  let collectionType = merchants.collectionType;

  const code = () => {
    switch (collectionType) {
      case "PAY":
        return <PayFlow merchants={merchants} />;

      case "LOOKUP_PAY_AMOUNT":
        return <LUPA merchants={merchants} />;

      case "LOOKUP_PAY":
        return <LUP merchants={merchants} />;

      case "LOOKUP_PAY_INVOICES":
        return <LUPI merchants={merchants} />;

      case "LOOKUP_PAY_OPTIONS":
        return <LUPO merchants={merchants} />;

      case "PAY_OPTIONS":
        return <PayOptions merchants={merchants} />;
    }
  };

  return (
    <div>
      {loading ? (
        <div className="main">
          <div className="main-content">
            <div className="main-content-logo">
              <img src={merchants.logoUrl} alt="logo" />
            </div>
            <div className="main-content-text">
              <div className="main-content-text-name">{merchants.name}</div>
              <div className="main-content-text-description">
                {merchants.description}
              </div>
            </div>
          </div>
          {code()}
        </div>
      ) : (
        <div className="main-loader">
          <LineWave
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="line-wave"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
        </div>
      )}
    </div>
  );
};

export default Main;
