import React, { useState, useEffect } from "react";
import { CheckoutOptions, Wizard } from "../../components";
import { useLocation, useParams } from "react-router-dom";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import axios from "axios";
import { Steps } from "antd";
import { ConfigProvider } from "antd";
import "./Checkout.css";
import Modal from "../../components/Modal/Modal";

const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  const [current, setCurrent] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [merchants, setMerchants] = useState({});

  let params = useParams();
  const invoiceNum =params.invoiceNum;
  const final = invoiceData.grandTotal;
  const total = invoiceData.paymentAmount;
  const fee = total - final;
  const fees = fee.toFixed(2);
  const finalFees = parseFloat(fees);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const invoiceResponse = await axios.post("/pos", {
          requestId: "{{$guid}}",
          username: "stanbic",
          password: "password",
          invoiceNum: invoiceNum,
          url: "/webpos/getInvoice",
        });
  
        setInvoiceData(invoiceResponse?.data?.result);
  
        const merchantsResponse = await axios.post("/core", {
          serviceCode: invoiceResponse?.data?.result.serviceCode,
          verbose: true,
          url: "/billing/collection",
        });
  
        setMerchants(merchantsResponse.data.data);
  
        setLoading(true);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);
 
  const code = () => {
    if (current === 0) {
      return (
        <>
          <button
            className="checkout-card-summary-charge-button"
            onClick={() => setOpenModal(true)}
          >
            Cancel Purchase
          </button>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            invoiceData={invoiceData}
          />
        </>
      );
    } else if (current === 1) {
      return (
        <>
          <button
            className="checkout-card-summary-charge-button"
            onClick={() => setOpenModal(true)}
          >
            Cancel Purchase
          </button>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            invoiceData={invoiceData}
          />
        </>
      );
    } else {
      return "";
    }
  };

  const arrowDown = () => {
    if (toggleMenu === true) {
      return (
        <MdKeyboardArrowUp
          className="arrow-down"
          onClick={() => setToggleMenu(false)}
          fontSize={27}
        />
      );
    } else {
      return (
        <MdKeyboardArrowDown
          className="arrow-down"
          onClick={() => setToggleMenu(true)}
          fontSize={27}
        />
      );
    }
  };

  return (
    <div className="checkout">
      <div className="checkout-card">
        <div className="checkout-card-options">
          <div className="checkout-card-options-wizard">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#00b96b",
                },
              }}
            >
              <>
                <Steps
                  progressDot
                  current={current}
                  style={{ progressDot: "green" }}
                  items={[
                    {
                      title: "Payment options",
                    },
                    {
                      title: "Details",
                    },
                    {
                      title: "Confirmation",
                    },
                  ]}
                />
              </>
            </ConfigProvider>
          </div>
          <div className="checkout-card-options-list">
            <CheckoutOptions
              invoiceData={invoiceData}
              setCurrent={setCurrent}
            />
          </div>
        </div>

        <div className="checkout-card-summary">
          <div className="checkout-card-summary-items">
            <div className="checkout-card-summary-items-header">
              Order Summary ({invoiceData.itemCount})
            </div>

            <div className="checkout-card-summary-items-content">
              <div className="checkout-card-summary-items-content-img">
                <img src={merchants.logoUrl} />
              </div>
              <div className="checkout-card-summary-items-content-info">
                <div className="checkout-card-summary-items-content-info-name">
                  <p>{merchants.name}</p>
                </div>
                <div className="checkout-card-summary-items-content-info-input">
                  {/* <p>{invoiceData.invoiceItems.name ? invoiceData.invoiceItems.name : "Customer Name"}</p> */}
                  <p className="bold"> GHS {invoiceData.invoiceAmount}</p>
                </div>
              </div>
            </div>
            <div className="line" />
          </div>

          <div className="checkout-card-summary-charge">
            <div className="checkout-card-summary-charge-fee">
              <p> Fees</p>
              <p> {invoiceData.fees}</p>
            </div>

            <div className="checkout-card-summary-charge-vat">
              <p>VAT</p>
              <p> {finalFees}</p>
            </div>
            <div className="checkout-card-summary-charge-total">
              <p className="bold"> Total </p>
              <p className="bold"> GHS {invoiceData.paymentAmount}</p>
            </div>
            {code()}
          </div>
        </div>

        <div className="checkout-card-summary-smallscreen">
          {arrowDown()}
          <div className="checkout-card-summary-items">
            <div className="checkout-card-summary-items-header ">
              Order Summary ({invoiceData.itemCount})
            </div>
            {/* .......... */}
            {toggleMenu && (
              <div className="slide-bottom">
                <div className="checkout-card-summary-items-content">
                  <div className="checkout-card-summary-items-content-img">
                    <img src={merchants.logoUrl} />
                  </div>
                  <div className="checkout-card-summary-items-content-info">
                    <div className="checkout-card-summary-items-content-info-name">
                      <p>{merchants.name}</p>
                    </div>
                    <div className="checkout-card-summary-items-content-info-input">
                    {/* <p>{invoiceData.invoiceItems.name ? invoiceData.invoiceItems.name : "Customer Name"}</p> */}
                      <p className="bold"> GHS {invoiceData.invoiceAmount}</p>
                    </div>
                  </div>
                </div>
                <div className="line" />

                <div className="checkout-card-summary-charge-fee ss-total">
                  <p> Fees</p>
                  <p> {invoiceData.fees}</p>
                </div>

                <div className="checkout-card-summary-charge-vat">
                  <p>VAT</p>
                  <p> {finalFees}</p>
                </div>
              </div>
            )}
            {/* ......... */}
            <div className="checkout-card-summary-charge-total ss-total">
              <p className="bold"> Total </p>
              <p className="bold"> GHS {invoiceData.paymentAmount}</p>
            </div>
            <div className="ss-button">{code()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Checkout;
