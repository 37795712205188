import React, { useEffect, useState } from "react";
import "./TravelPolicyDetails.css";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Oval } from "react-loader-spinner";
import { RiArrowDropDownLine, RiCalendar2Line } from "react-icons/ri";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const TravelPolicyDetails = () => {
  const { serviceCode } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customerCode, setCustomerCode] = useState();

  const [country, setCountry] = useState("");

  const [nationalityCode, setNationalityCode] = useState("");
  const [nationalityName, setNationalityName] = useState("");
  const [address, setAddress] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [issuingCountryCode, setIssuingCountryCode] = useState("");
  const [issuingCountryName, setIssuingCountryName] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [embassyAddress, setEmbassyAddress] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [beneficiaryRelationship, setBeneficiaryRelationship] = useState("");
  const [beneficiaryNumber, setBeneficiaryNumber] = useState("");
  const [isMedicalChecked, setIsMedicalChecked] = useState(false);
  const [medicalDetails, setMedicalDetails] = useState("");
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [declarationDetails, setDeclarationDetails] = useState("");

  const [nationalityCodeError, setNationalityCodeError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [passportNumberError, setPassportNumberError] = useState("");
  const [issuingCountryCodeError, setIssuingCountryCodeError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [embassyAddressError, setEmbassyAddressError] = useState("");
  const [beneficiaryNameError, setBeneficiaryNameError] = useState("");
  const [beneficiaryRelationshipError, setBeneficiaryRelationshipError] = useState("");
  const [beneficiaryNumberError, setBeneficiaryNumberError] = useState("");
  const [medicalDetailsError, setMedicalDetailsError] = useState("");
  const [declarationDetailsError, setDeclarationDetailsError] = useState("");

  const [isOpenPersonal, setIsOpenPersonal] = useState(false);
  const [isOpenPassport, setIsOpenPassport] = useState(false);
  const [isOpenEmbassy, setIsOpenEmbassy] = useState(false);
  const [isOpenBeneficiary, setIsOpenBeneficiary] = useState(false);
  const [isOpenMedical, setIsOpenMedical] = useState(false);
  const [isOpenDeclaration, setIsOpenDeclaration] = useState(false);

  const toggleCollapsePersonal = () => setIsOpenPersonal(!isOpenPersonal);
  const toggleCollapsePassport = () => setIsOpenPassport(!isOpenPassport);
  const toggleCollapseEmbassy = () => setIsOpenEmbassy(!isOpenEmbassy);
  const toggleCollapseBeneficiary = () =>
    setIsOpenBeneficiary(!isOpenBeneficiary);
  const toggleCollapseMedical = () => setIsOpenMedical(!isOpenMedical);
  const toggleCollapseDeclaration = () =>
    setIsOpenDeclaration(!isOpenDeclaration);

    const storedCustomerDetails = localStorage.getItem("customerDetails");
    const customerDetails = JSON.parse(storedCustomerDetails);

  useEffect(() => {
    const fetchData = async () => {
      let uuid = uuidv4();
      setLoading(true);
      try {
        setLoading(true);

        const countryResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "country",
          async: true,
          params: {},
          url: "/switching/process",
        });

        setCountry(countryResponse?.data?.result);
        setIsOpenPersonal(true);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content =
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";

    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  useEffect(() => {
    const storedTravelPolicyDetails = localStorage.getItem(
      "travelPolicyDetails"
    );
    if (storedTravelPolicyDetails) {
      const parsedDetails = JSON.parse(storedTravelPolicyDetails);
      setNationalityCode(parsedDetails.nationalityCode);
      setNationalityName(parsedDetails.nationalityName);
      setAddress(parsedDetails.address);
      setPassportNumber(parsedDetails.passportNumber);
      setIssuingCountryCode(parsedDetails.issuingCountryCode);
      setIssuingCountryName(parsedDetails.issuingCountryName);
      setExpiryDate(parsedDetails.expiryDate);
      setEmbassyAddress(parsedDetails.embassyAddress);
      setBeneficiaryName(parsedDetails.beneficiaryName);
      setBeneficiaryRelationship(parsedDetails.beneficiaryRelationship);
      setBeneficiaryNumber(parsedDetails.beneficiaryNumber);
      setIsMedicalChecked(parsedDetails.isMedicalChecked);
      setMedicalDetails(parsedDetails.medicalDetails);
      setIsDeclarationChecked(parsedDetails.isDeclarationChecked);
      setDeclarationDetails(parsedDetails.declarationDetails);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const customerResponse = await axios.post("/getCustomer", {
          accountId: "",
          serviceCode: serviceCode,
          customerId: customerDetails?.phoneNumber,
        });

        if (customerResponse?.data?.data) {
          setCustomerCode(customerResponse?.data?.data?.details?.code);
        } else {
          setCustomerCode("");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [serviceCode]);

  const generatePremium = () => {
    let hasError = false;

    if (nationalityCode === "") {
      setNationalityCodeError(<FormattedMessage id=  "component.travel.nationalityCodeError"/>);
      hasError = true;
    } else {
      setNationalityCodeError("");
    }

    if (address === "") {
      setAddressError(<FormattedMessage id=  "component.travel.addressError"/>);
      hasError = true;
    } else {
      setAddressError("");
    }

    if (passportNumber === "") {
      setPassportNumberError(<FormattedMessage id=  "component.travel.passportNumberError"/>);
      hasError = true;
    } else {
      setPassportNumberError("");
    }

    if (issuingCountryCode === "") {
      setIssuingCountryCodeError(<FormattedMessage id=  "component.travel.issuingCountryCodeError"/>);
      hasError = true;
    } else {
      setIssuingCountryCodeError("");
    }

    if (expiryDate === "") {
      setExpiryDateError(<FormattedMessage id=  "component.travel.expiryDateError"/>);
      hasError = true;
    } else {
      setExpiryDateError("");
    }

    if (embassyAddress === "") {
      setEmbassyAddressError(<FormattedMessage id=  "component.travel.embassyAddressError"/>);
      hasError = true;
    } else {
      setEmbassyAddressError("");
    }
    if (beneficiaryName === "") {
      setBeneficiaryNameError(<FormattedMessage id=  "component.travel.beneficiaryNameError"/>);
      hasError = true;
    } else {
      setBeneficiaryNameError("");
    }
    if (beneficiaryRelationship === "") {
      setBeneficiaryRelationshipError(<FormattedMessage id=  "component.travel.beneficiaryRelationshipError"/>);
      hasError = true;
    } else {
      setBeneficiaryRelationshipError("");
    }
    if (beneficiaryNumber === "") {
      setBeneficiaryNumberError(<FormattedMessage id=  "component.travel.beneficiaryNumberError"/>);
      hasError = true;
    } else {
      setBeneficiaryNumberError("");
    }

    if (isMedicalChecked && medicalDetails === "") {
      setMedicalDetailsError(<FormattedMessage id=  "component.travel.medicalDetailsError"/>);
      hasError = true;
    } else {
      setMedicalDetailsError("");
    }

    if (isDeclarationChecked && declarationDetails === "") {
      setDeclarationDetailsError(<FormattedMessage id=  "component.travel.declarationDetailsError"/>);
      hasError = true;
    } else {
      setDeclarationDetailsError("");
    }

    if (!hasError) {
      const travelPolicyDetails = {
        nationalityCode: nationalityCode,
        nationalityName: nationalityName,
        address: address,
        passportNumber: passportNumber,
        issuingCountryCode: issuingCountryCode,
        issuingCountryName: issuingCountryName,
        expiryDate: expiryDate,
        embassyAddress: embassyAddress,
        beneficiaryName: beneficiaryName,
        beneficiaryRelationship: beneficiaryRelationship,
        beneficiaryNumber: beneficiaryNumber,
        isMedicalChecked: isMedicalChecked,
        medicalDetails: medicalDetails,
        isDeclarationChecked: isDeclarationChecked,
        declarationDetails: declarationDetails,
      };
      localStorage.setItem(
        "travelPolicyDetails",
        JSON.stringify(travelPolicyDetails)
      );
      navigate(`/travel/${serviceCode}/travelPayPremium`);
      localStorage.setItem("customerCode", JSON.stringify(customerCode));
    } else {
      if (nationalityCodeError) {
        setIsOpenPersonal(true);
      }
      if (passportNumberError || issuingCountryCodeError || expiryDateError) {
        setIsOpenPassport(true);
      }
      if (embassyAddressError) {
        setIsOpenEmbassy(true);
      }
      if (beneficiaryNameError||beneficiaryRelationshipError||beneficiaryNumberError) {
        setIsOpenBeneficiary(true);
      }
      if (medicalDetailsError) {
        setIsOpenMedical(true);
      }
      if (declarationDetailsError) {
        setIsOpenDeclaration(true);
      }
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };
  const preventNonNumericInput = (event) => {
    const key = event.key;

    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "+",
    ];

    const isNumeric = !isNaN(parseInt(key));

    if (!allowedKeys.includes(key) && !isNumeric) {
      event.preventDefault();
    }
  };

  return (
    <>
      {loading ? (
       <div className="jsonForm-loader">
       <Oval
         visible={true}
         height="40"
         width="40"
         color="#4fa94d"
         ariaLabel="oval-loading"
         wrapperStyle={{}}
         wrapperClass=""
       />
        </div>
      ) : (
        <div className="MotorInsurance">
          <div className="wizard">
            <div className="wizardComponent">
              <div className="TravelDetails" style={{ color: "#000" }}>
              <FormattedMessage id="component.travel.travelDetails"/>
              </div>
              <div className="CustomerDetails" style={{ color: "#000" }}>
              <FormattedMessage id="component.travel.policyDetails"/>
              </div>
              <div className="Premium2" style={{ color: "#000" }}>
              <FormattedMessage id="component.getQuote"/>
              </div>
              <div className="Payment"><FormattedMessage id="component.payment"/></div>
              <div
                className="Rectangle1"
                style={{ background: "#40AD52" }}
              ></div>
              <div
                className="Rectangle2"
                style={{ background: "#40AD52" }}
              ></div>
              <div className="Rectangle3"></div>
              <div className="Ellipse">
                <div
                  className="EllipsePremium"
                  style={{ background: "#40AD52" }}
                ></div>
                <div
                  className="EllipseCustomerDetails"
                  style={{ background: "#40AD52" }}
                ></div>
                <div className="EllipsePayment"></div>
                <div
                  className="EllipseTravelDetails"
                  style={{ background: "#40AD52" }}
                ></div>
              </div>
            </div>
          </div>
          <div className="MotorInsuranceTitle">
            <h3><FormattedMessage id="component.travel.policyDetails"/></h3>
          </div>

          <form className="form">
            <div className="collapse-section">
              <div className="collapse-header" onClick={toggleCollapsePersonal}>
              <h3><FormattedMessage id="component.travel.personalDetails"/></h3>
                {isOpenPersonal ? (
                  <span>
                    <FaAngleUp className="arrow" />
                  </span>
                ) : (
                  <span>
                    <FaAngleDown className="arrow" />
                  </span>
                )}
              </div>
              {isOpenPersonal && (
                <div>
                  <div className="formPosition">
                    <label><FormattedMessage id="component.travel.nationality"/></label>
                    <div className="selectContainer">
                      <select
                        name=""
                        className="formStyle"
                        value={nationalityCode}
                        onChange={(e) => {
                          setNationalityCode(e.target.value);
                          const selectedRisk = country.find(
                            (item) => item.CODE === e.target.value
                          );
                          setNationalityName(selectedRisk?.NAME);
                        }}
                      >
                        <option value=""><FormattedMessage id="component.travel.nationality.option"/></option>
                        {country?.map((item, index) => (
                          <option
                            value={item.CODE}
                            key={index}
                            style={{ padding: 9 }}
                          >
                            {item.NAME}
                          </option>
                        ))}
                      </select>
                      <RiArrowDropDownLine className="selectIcon" />
                    </div>
                    <div className="MotorInsuranceerror">
                      {nationalityCodeError && (
                        <span className="shake shake1">
                          {nationalityCodeError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="formPosition">
                    <label><FormattedMessage id="component.travel.address"/></label>
                    <div className="selectContainer">
                      <textarea
                        name=""
                        className="textareaStyle"
                        rows={4}
                        cols={50}
                        value={address}
                        onChange={(e) => {
                          const value = e.target.value.slice(0, 120);
                          setAddress(value);
                        }}
                      />
                    </div>
                    <div className="MotorInsuranceerror">
                      {addressError && (
                        <span className="shake shake1">{addressError}</span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="collapse-section">
              <div className="collapse-header" onClick={toggleCollapsePassport}>
              <FormattedMessage id="component.travel.passportDetails"/>
                {isOpenPassport ? (
                  <span>
                    <FaAngleUp className="arrow" />
                  </span>
                ) : (
                  <span>
                    <FaAngleDown className="arrow" />
                  </span>
                )}
              </div>
              {isOpenPassport && (
                <div>
                  <div className="formPosition">
                    <label><FormattedMessage id="component.travel.passportNumber"/></label>
                    <div className="selectContainer">
                      <input
                        name=""
                        className="formStyle"
                        value={passportNumber}
                        onChange={(e) => {
                          const value = e.target.value.slice(0, 20);
                          setPassportNumber(value);
                        }}
                      />
                    </div>
                    <div className="MotorInsuranceerror">
                      {passportNumberError && (
                        <span className="shake shake1">
                          {passportNumberError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="datePickersPosition">
                    <div className="formPosition">
                      <label><FormattedMessage id="component.travel.issuingCountryCode"/></label>
                      <div className="selectContainer">
                        <select
                          name=""
                          className="datePickersStyle"
                          value={issuingCountryCode}
                          onChange={(e) => {
                            setIssuingCountryCode(e.target.value);
                            const selectedRisk = country.find(
                              (item) => item.CODE === e.target.value
                            );
                            setIssuingCountryName(selectedRisk?.NAME);
                          }}
                        >
                          <option value=""><FormattedMessage id="component.travel.issuingCountryCode.option"/></option>
                          {country.map((item, index) => (
                            <option
                              value={item.CODE}
                              key={index}
                              style={{ padding: 9 }}
                            >
                              {item.NAME}
                            </option>
                          ))}
                        </select>
                        <RiArrowDropDownLine className="selectIcon" />
                      </div>
                      <div className="MotorInsuranceerror">
                        {issuingCountryCodeError && (
                          <span className="shake shake1">
                            {issuingCountryCodeError}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="formPosition">
                      <label><FormattedMessage id="component.travel.expiryDate"/></label>
                      <div className="datePickerContainer">
                        <input
                          type="date"
                          name=""
                          className="datePickersStyle"
                          value={expiryDate}
                          onChange={(e) => {
                            setExpiryDate(e.target.value);
                          }}
                        />
                        <RiCalendar2Line className="datePickerIcon" />
                      </div>
                      <div className="MotorInsuranceerror">
                        {expiryDateError && (
                          <span className="shake shake1">
                            {expiryDateError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="collapse-section">
              <div className="collapse-header" onClick={toggleCollapseEmbassy}>
              <FormattedMessage id="component.travel.embassyDetails"/>
                {isOpenEmbassy ? (
                  <span>
                    <FaAngleUp className="arrow" />
                  </span>
                ) : (
                  <span>
                    <FaAngleDown className="arrow" />
                  </span>
                )}
              </div>
              {isOpenEmbassy && (
                <div>
                  <div className="formPosition">
                    <label><FormattedMessage id="component.travel.embassyAddress"/></label>
                    <div className="selectContainer">
                      <textarea
                        name=""
                        className="textareaStyle"
                        rows={4}
                        cols={50}
                        value={embassyAddress}
                        onChange={(e) => {
                          const value = e.target.value.slice(0, 120);
                          setEmbassyAddress(value);
                        }}
                      />
                    </div>
                    <div className="MotorInsuranceerror">
                      {embassyAddressError && (
                        <span className="shake shake1">
                          {embassyAddressError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="collapse-section">
              <div
                className="collapse-header"
                onClick={toggleCollapseBeneficiary}
              >
                <FormattedMessage id="component.travel.beneficiary"/>
                {isOpenBeneficiary ? (
                  <span>
                    <FaAngleUp className="arrow" />
                  </span>
                ) : (
                  <span>
                    <FaAngleDown className="arrow" />
                  </span>
                )}
              </div>
              {isOpenBeneficiary && (
                <div className="collapse-content">
                  <div className="formPosition ">
                    <label><FormattedMessage id="component.travel.beneficiaryName"/></label>
                    <div className="selectContainer">
                      <input
                        type="text"
                        name=""
                        className="formStyle"
                        value={beneficiaryName}
                        onChange={(e) => {
                          const value = e.target.value.slice(0, 50);
                          setBeneficiaryName(value);
                        }}
                      />
                    </div>
                    <div className="MotorInsuranceerror">
                      {beneficiaryNameError && (
                        <span className="shake shake1">
                          {beneficiaryNameError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="formPosition">
                    <label><FormattedMessage id="component.travel.beneficiaryRelationship"/></label>
                    <div className="selectContainer">
                      <select
                        name=""
                        className="formStyle"
                        value={beneficiaryRelationship}
                        onChange={(e) => {
                          setBeneficiaryRelationship(e.target.value);
                        }}
                      >
                        <option value=""><FormattedMessage id="component.travel.beneficiaryRelationship.option"/></option>
                        <option value="Husband">Husband</option>
                        <option value="Wife">Wife</option>
                        <option value="Son">Son</option>
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Nephew">Nephew</option>
                        <option value="Niece">Niece</option>
                        <option value="Cousin">Cousin</option>
                        <option value="Aunty">Aunty</option>
                        <option value="Grand-father">Grand-father</option>
                        <option value="Grand-mother">Grand-mother</option>
                      </select>
                        <RiArrowDropDownLine className="selectIcon" />
                    </div>
                    <div className="MotorInsuranceerror">
                      {beneficiaryRelationshipError && (
                        <span className="shake shake1">
                          {beneficiaryRelationshipError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="formPosition">
                    <label><FormattedMessage id="component.travel.beneficiaryNumber"/></label>
                    <div className="selectContainer">
                      <input
                        type="tel"
                        name=""
                        className="formStyle"
                        placeholder="eg. +233201234567"
                        value={beneficiaryNumber}
                        onChange={(e) => {
                          const value = e.target.value.slice(0, 13);
                          setBeneficiaryNumber(value);
                        }}
                        onKeyDown={(e) => preventNonNumericInput(e)}
                      />
                    </div>
                    <div className="MotorInsuranceerror">
                      {beneficiaryNumberError && (
                        <span className="shake shake1">
                          {beneficiaryNumberError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="collapse-section">
              <div className="collapse-header" onClick={toggleCollapseMedical}>
              <FormattedMessage id="component.travel.medicalCondition"/>
                {isOpenMedical ? (
                  <span>
                    <FaAngleUp className="arrow" />
                  </span>
                ) : (
                  <span>
                    <FaAngleDown className="arrow" />
                  </span>
                )}
              </div>
              {isOpenMedical && (
                <div className="collapse-content">
                  <div className="checkbox">
                    <label className="checkbox-label">
                    <FormattedMessage id="component.travel.medicalCondition.description"/>
                    </label>
                    <div className="checkbocPosition">
                      <input
                        type="checkbox"
                        name="option"
                        value="Yes"
                        checked={isMedicalChecked}
                        onChange={(e) => setIsMedicalChecked(e.target.checked)}
                        className="checkboxStyle"
                      />
                      <label><FormattedMessage id="component.travel.yes"/> </label>
                    </div>

                    <div className="checkbocPosition">
                      <input
                        type="checkbox"
                        name="option"
                        value="No"
                        checked={!isMedicalChecked}
                        onChange={(e) => setIsMedicalChecked(!e.target.checked)}
                        className="checkboxStyle"
                      />
                      <label><FormattedMessage id="component.travel.no"/> </label>
                    </div>
                  </div>
                  {isMedicalChecked && (
                    <>
                      <div className="formPosition">
                        <label><FormattedMessage id="component.travel.medicalCondition.isMedicalChecked"/></label>
                        <textarea
                          className="textareaStyle"
                          rows={4}
                          cols={50}
                          value={medicalDetails}
                          onChange={(e) => {
                            const value = e.target.value.slice(0, 120);
                            setMedicalDetails(value);
                          }}
                        />
                      </div>
                      <div className="MotorInsuranceerror">
                        {medicalDetailsError && (
                          <span className="shake shake1">
                            {medicalDetailsError}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="collapse-section">
              <div
                className="collapse-header"
                onClick={toggleCollapseDeclaration}
              >
                <FormattedMessage id="component.travel.declaration"/>
                {isOpenDeclaration ? (
                  <span>
                    <FaAngleUp className="arrow" />
                  </span>
                ) : (
                  <span>
                    <FaAngleDown className="arrow" />
                  </span>
                )}
              </div>
              {isOpenDeclaration && (
                <div className="collapse-content">
                  <div className="checkbox">
                    <label className="checkbox-label">
                    <FormattedMessage id="component.travel.declaration.description"/>
                    </label>
                    <div className="checkbocPosition">
                      <input
                        type="checkbox"
                        name="option"
                        value="Yes"
                        checked={isDeclarationChecked}
                        onChange={(e) =>
                          setIsDeclarationChecked(e.target.checked)
                        }
                        className="checkboxStyle"
                      />
                      <label><FormattedMessage id="component.travel.yes"/> </label>
                    </div>

                    <div className="checkbocPosition">
                      <input
                        type="checkbox"
                        name="option"
                        value="No"
                        checked={!isDeclarationChecked}
                        onChange={(e) =>
                          setIsDeclarationChecked(!e.target.checked)
                        }
                        className="checkboxStyle"
                      />
                      <label><FormattedMessage id="component.travel.no"/> </label>
                    </div>
                  </div>
                  {isDeclarationChecked && (
                    <>
                      <div className="formPosition">
                        <label><FormattedMessage id="component.travel.declaration.isDeclarationChecked"/></label>
                        <textarea
                          className="textareaStyle"
                          rows={4}
                          cols={50}
                          value={declarationDetails}
                          onChange={(e) => {
                            const value = e.target.value.slice(0, 120);
                            setDeclarationDetails(value);
                          }}
                        />
                      </div>
                      <div className="MotorInsuranceerror">
                        {declarationDetailsError && (
                          <span className="shake shake1">
                            {declarationDetailsError}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </form>

          <div className="button">
            <div className="button-double">
              <button className="button-gray" onClick={handleBackClick}>
              <FormattedMessage id="component.button.back"/>
              </button>
              <button className="button-green" onClick={generatePremium}>
              <FormattedMessage id="component.button.next"/>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TravelPolicyDetails;
