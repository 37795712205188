
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Vortex } from "react-loader-spinner";
import "./PayOptions.css";

const ListItems = (props) => {
  return (
    <div className="payoption-item">
      <div className="payoption-list">
        <input
          inline="true"
          name="group1"
          type="radio"
          id="2"
          value={props.price}
          className="payoption-list-radio"
          onChange={props.select}
          checked={props.setIsRadioChecked(props.itemCode)}
        />

        <div className="payoption-list-data">
          <p className="payoption-list-data-name">{props.name}</p>
          <p className="payoption-list-data-amount"> GHS {props.price}</p>
        </div>
      </div>
      <div className="payoption-line" />
    </div>
  );
};

const PayOptions = ({ merchants }) => {
  let params = useParams();
  const navigate = useNavigate();
  const param = params.serviceCode;
  const [invoiceData, setInvoiceData] = useState("");

  const [totalBill, setTotalBill] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("");
  const [checked, setChecked] = useState(false);
  const [isRadioChecked, setIsRadioChecked] = useState("");
  const [refnum, setRefnum] = useState("");
  const [inputError, setInputError] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);

  const fixedItems = merchants.fixedBillItems;

  const select = (e) => {
    setChecked(!checked);
    setTotalBill(e.target.value);
  };

  const pay = async (e) => {
    try {
      e.preventDefault();
      setSearchLoader(true);
      if (refnum === "") {
        setInputError("Please input a reference");
        setSearchLoader(false);
      } else {
      const dat = await axios
        .post("pos", {
          requestId: "4d55391b-f463-4df7-8918-578d8eec94b5",
          username: "stanbic",
          password: "password",
          merchantOrderId: "202008241855",
          serviceCode: param,
          currency: "GHS",
          amount: totalBill,
          reference: refnum,
          url: "/webpos/createInvoice",
        })
        .then((res) => {
          const invoiceCreation = res.data.result;
          setInvoiceData(invoiceCreation);
          console.log(invoiceCreation, "invoice data");
          // navigate("/checkout", {
          //   state: {
          //     merchant: merchants,
          //     invoice: invoiceCreation,
          //     refnum: refnum,
          //   },
          // });
          const invoiceNum=invoiceCreation.invoiceNum
          navigate(`/checkout/${invoiceNum}`, {
            state: {
              merchant: merchants,
              invoice: invoiceCreation,
              refnum: refnum,
            },
          });
        });
      setSearchLoader(false);
      }
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    pay();
  }, []);

  return (
    <div className="payoption">
      <form className="payoption-form">
        <input
          type="text"
          placeholder={merchants.referenceField}
          className="payoption-form-input"
          value={refnum}
          onChange={(e) => setRefnum(e.target.value)}
        />
        <div className="payoption-form-input-error">
          {!refnum && <span className="shake shake1">{inputError} </span>}
        </div>
      </form>

      <div fluid className="payoption-scroll">
        {fixedItems.map(({ itemCode, itemName, price }) => (
          <ListItems
            key={itemCode}
            price={price}
            name={itemName}
            itemCode={itemCode}
            checked={checked}
            select={select}
            setIsRadioChecked={setIsRadioChecked}
          />
        ))}
      </div>

      <div className="payflow-form-total">
        <h6>Total : GHS {totalBill ? totalBill : 0.0} </h6>
      </div>
      <button className="payflow-form-button" disabled={!checked} onClick={pay}>
        Continue To Checkouct
        {searchLoader && (
          <Vortex
            visible={true}
            height="40"
            width="40"
            ariaLabel="vortex-loading"
            wrapperStyle={{}}
            wrapperClass="vortex-wrapper"
            colors={["white", "white", "white", "white", "white", "white"]}
          />
        )}
      </button>
    </div>
  );
};

export default PayOptions;
