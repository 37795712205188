import React, { useEffect, useState } from "react";
import "./PolicyDetails.css";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiCalendar2Line } from "react-icons/ri";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Oval } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { addMonths, addDays } from "date-fns";

const PolicyDetails = () => {
  const { serviceCode } = useParams();
  const navigate = useNavigate();

  const storedVinDetails = localStorage.getItem("vinDetails");
  const vinDetails = JSON.parse(storedVinDetails);

  const displacementCC = parseInt(vinDetails?.displacementCC,10)
  const displacementL = parseFloat(vinDetails?.displacementL).toFixed(1);

  const [loading, setLoading] = useState(false);
  const [policy, setPolicy] = useState([]);
  const [risk, setRisk] = useState([]);

  const [policyType, setPolicyType] = useState("");
  const [policyName, setPolicyName] = useState("");
  const [riskType, setRiskType] = useState("");
  const [riskName, setRiskName] = useState("");
  const [registrationYear, setRegistrationYear] = useState("");
  const [vehicleYear, setVehicleYear] = useState(
    vinDetails?.modelYear ? vinDetails?.modelYear : ""
  );
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState("");
  const [duration, setDuration] = useState("");
  const [cubicCapacity, setCubicCapacity] = useState(displacementCC ? displacementCC: "");
  const [liter, setLiter] = useState(displacementCC ? displacementL: "");
  const [vehicleValue, setVehicleValue] = useState("");
  const [nOP, setNOP] = useState("");
  const [isDamageLimitEnabled, setIsDamageLimitEnabled] = useState(false);
  const [buyBackExcessEnabled, setBuyBackExcessEnabled] = useState(false);
  const [amount, setAmount] = useState("0");

  const [policyTypeError, setPolicyTypeError] = useState("");
  const [riskTypeError, setRiskTypeError] = useState("");
  const [registrationYearError, setRegistrationYearError] = useState("");
  const [vehicleYearError, setVehicleYearError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [durationError, setDurationError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [cubicCapacityError, setCubicCapacityError] = useState("");
  const [vehicleValueError, setVehicleValueError] = useState("");
  const [nOPError, setNOPError] = useState("");

  const [buyForSomeone, setBuyForSomeone] = useState(false);
  const [recipientFirstName, setRecipientFirstName] = useState("");
  const [recipientLastName, setRecipientLastName] = useState("");
  const [recipientNumber, setRecipientNumber] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientDateOfBirth, setRecipientDateOfBirth] = useState("");
  const [recipientIdType, setRecipientIdType] = useState("");
  const [recipientIdNumber, setRecipientIdNumber] = useState("");

  const [recipientFirstNameError, setRecipientFirstNameError] = useState("");
  const [recipientLastNameError, setRecipientLastNameError] = useState("");
  const [recipientNumberError, setRecipientNumberError] = useState("");
  const [recipientEmailError, setRecipientEmailError] = useState("");
  const [recipientDateOfBirthError, setRecipientDateOfBirthError] =
    useState("");
  const [recipientIdTypeError, setRecipientIdTypeError] = useState("");
  const [recipientIdNumberError, setRecipientIdNumberError] = useState("");

  const [isBuyForSomeone, setIsBuyForSomeone] = useState(true);
  const toggleCollapseBuyForSomeone = () =>
    setIsBuyForSomeone(!isBuyForSomeone);
  const [isOpenVehicle, setIsOpenVehicle] = useState(false);
  const toggleCollapseVehicle = () => setIsOpenVehicle(!isOpenVehicle);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const toggleCollapsePolicy = () => setIsOpenPolicy(!isOpenPolicy);

  const [showLiter, setShowLiter] = useState(true);
  const [showCubicCapacity, setShowCubicCapacity] = useState(false);
  const [selectedButton, setSelectedButton] = useState("liter");

  const handleLiterClick = (e) => {
    e.preventDefault();
    setShowLiter(true);
    setShowCubicCapacity(false);
    setSelectedButton("liter");
  };

  const handleCubicCapacityClick = (e) => {
    e.preventDefault();
    setShowCubicCapacity(true);
    setShowLiter(false);
    setSelectedButton("cubicCapacity");
  };

  useEffect(() => {
    const fetchData = async () => {
      let uuid = uuidv4();
      try {
        setLoading(true);
        const PolicyTypeResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "motorProducts",
          async: true,
          params: {},
          url: "/switching/process",
        });

        setPolicy(PolicyTypeResponse.data.result);
        setIsOpenVehicle(true);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let uuid = uuidv4();
      try {
        const RiskTypeResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "motorRisks",
          async: true,
          params: { productCode: policyType, vehicleRegYear: registrationYear },
          url: "/switching/process",
        });

        setRisk(RiskTypeResponse.data.result);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [policyType, policyName, registrationYear]);

  useEffect(() => {
    const storedPolicyDetails = localStorage.getItem("policyDetails");
    const storedRecipientDetails = localStorage.getItem("recipientDetails");
    if (storedPolicyDetails) {
      const parsedDetails = JSON.parse(storedPolicyDetails);
      setPolicyType(parsedDetails.policyType);
      setPolicyName(parsedDetails.policyName);
      setRiskType(parsedDetails.riskType);
      setRiskName(parsedDetails.riskName);
      setRegistrationYear(parsedDetails.registrationYear);
      setVehicleYear(
        vinDetails?.modelYear
          ? vinDetails?.modelYear
          : parsedDetails.vehicleYear
      );
      setStartDate(parsedDetails.startDate);
      setEndDate(parsedDetails.endDate);
      setDuration(parsedDetails.duration);
      setIsDamageLimitEnabled(parsedDetails.isDamageLimitEnabled);
      setBuyBackExcessEnabled(parsedDetails.buyBackExcessEnabled);
      setAmount(parsedDetails.amount);
      setCubicCapacity(!isNaN(displacementCC) ? displacementCC : (parsedDetails?.cubicCapacity || ""));
      setLiter(!isNaN(displacementL) ? displacementL : (parsedDetails?.liter || ""));
      setVehicleValue(parsedDetails.vehicleValue);
      setNOP(parsedDetails.nOP);
      setSelectedButton(parsedDetails.selectedButton);
    }
    if (storedRecipientDetails) {
      const parsedDetails = JSON.parse(storedRecipientDetails);
      setRecipientFirstName(parsedDetails.recipientFirstName);
      setRecipientLastName(parsedDetails.recipientLastName);
      setRecipientNumber(parsedDetails.recipientNumber);
      setRecipientEmail(parsedDetails.recipientEmail);
      setRecipientDateOfBirth(parsedDetails.recipientDateOfBirth);
      setRecipientIdType(parsedDetails.recipientIdType);
      setRecipientIdNumber(parsedDetails.recipientIdNumber);
      setBuyForSomeone(parsedDetails.buyForSomeone);
    }
  }, []);

  useEffect(() => {
    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content =
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";

    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  const generatePremium = () => {
    let hasError = false;

    if (policyType === "") {
      setPolicyTypeError(<FormattedMessage id="component.policyTypeError" />);
      hasError = true;
    } else {
      setPolicyTypeError("");
    }

    if (riskType === "") {
      setRiskTypeError(<FormattedMessage id="component.riskTypeError" />);
      hasError = true;
    } else {
      setRiskTypeError("");
    }
    if (registrationYear === "") {
      setRegistrationYearError(
        <FormattedMessage id="component.registrationYearError" />
      );
      hasError = true;
    } else {
      setRegistrationYearError("");
    }

    if (vehicleYear === "") {
      setVehicleYearError(<FormattedMessage id="component.vehicleYearError" />);
      hasError = true;
    } else {
      setVehicleYearError("");
    }
    if (startDate === "") {
      setStartDateError(<FormattedMessage id="component.startDateError" />);
      hasError = true;
    } else {
      setStartDateError("");
    }

    if (duration === "") {
      setDurationError(<FormattedMessage id="component.durationError" />);
      hasError = true;
    } else {
      setDurationError("");
    }

    if (isDamageLimitEnabled && amount === "") {
      setAmountError(<FormattedMessage id="component.amountError" />);
      hasError = true;
    } else {
      setAmountError("");
    }

    if (cubicCapacity === "" && liter === "") {
      setCubicCapacityError(
        <FormattedMessage id="component.cubicCapacityError" />
      );
      hasError = true;
    } else {
      setCubicCapacityError("");
    }

    if (["6001", "6006", "6013"].includes(policyType) && vehicleValue <= 0) {
      setVehicleValueError("Value should be a number greater than 0");
      hasError = true;
    } else {
      setVehicleValueError("");
    }

    if (nOP === "") {
      setNOPError(<FormattedMessage id="component.nOPError" />);
      hasError = true;
    } else {
      setNOPError("");
    }

    if (buyForSomeone === true && recipientFirstName === "") {
      setRecipientFirstNameError(
        <FormattedMessage id="component.buyForSomeone.firstNameError" />
      );
      hasError = true;
    } else {
      setRecipientFirstNameError("");
    }
    if (buyForSomeone === true && recipientLastName === "") {
      setRecipientLastNameError(
        <FormattedMessage id="component.buyForSomeone.lastNameError" />
      );
      hasError = true;
    } else {
      setRecipientLastNameError("");
    }
    if (buyForSomeone === true && recipientNumber === "") {
      setRecipientNumberError(
        <FormattedMessage id="component.buyForSomeone.phoneNumberError" />
      );
      hasError = true;
    } else {
      setRecipientNumberError("");
    }
    if (buyForSomeone === true && recipientEmail === "") {
      setRecipientEmailError(
        <FormattedMessage id="component.buyForSomeone.emailError" />
      );
      hasError = true;
    } else {
      setRecipientEmailError("");
    }
    if (buyForSomeone === true && recipientDateOfBirth === "") {
      setRecipientDateOfBirthError(
        <FormattedMessage id="component.buyForSomeone.dateOfBirthError" />
      );
      hasError = true;
    } else {
      setRecipientDateOfBirthError("");
    }
    if (buyForSomeone === true && recipientIdType === "") {
      setRecipientIdTypeError(
        <FormattedMessage id="component.buyForSomeone.idTypeError" />
      );
      hasError = true;
    } else {
      setRecipientIdTypeError("");
    }
    if (buyForSomeone === true && recipientIdNumber === "") {
      setRecipientIdNumberError(
        <FormattedMessage id="component.buyForSomeone.idNumberError" />
      );
      hasError = true;
    } else {
      setRecipientIdNumberError("");
    }

    if (!hasError) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDiff = Math.abs(end.getTime() - start.getTime());
      const policyDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      const policyDetails = {
        policyType: policyType,
        policyName: policyName,
        riskType: riskType,
        riskName: riskName,
        registrationYear: registrationYear,
        vehicleYear: vehicleYear,
        cubicCapacity: cubicCapacity,
        liter: liter,
        vehicleValue: vehicleValue,
        nOP: nOP,
        startDate: startDate,
        endDate: endDate,
        duration: duration,
        policyDays: policyDays,
        isDamageLimitEnabled: isDamageLimitEnabled,
        buyBackExcessEnabled: buyBackExcessEnabled,
        amount: amount,
        selectedButton: selectedButton,
      };
      localStorage.setItem("policyDetails", JSON.stringify(policyDetails));
      if (buyForSomeone) {
        const recipientDetails = {
          buyForSomeone:buyForSomeone,
          recipientFirstName: recipientFirstName,
          recipientLastName: recipientLastName,
          recipientNumber: recipientNumber,
          recipientEmail: recipientEmail,
          recipientDateOfBirth: recipientDateOfBirth,
          recipientIdType: recipientIdType,
          recipientIdNumber: recipientIdNumber,
        };
        localStorage.setItem("recipientDetails",JSON.stringify(recipientDetails)
        );
      }
      if(!buyForSomeone){
        const recipientDetails = {
          buyForSomeone:buyForSomeone,
          recipientFirstName: "",
          recipientLastName: "",
          recipientNumber: '',
          recipientEmail: "",
          recipientDateOfBirth: "",
          recipientIdType: "",
          recipientIdNumber: "",
        };
        localStorage.setItem("recipientDetails",JSON.stringify(recipientDetails)
        );
      }
      navigate(`/vehicle/${serviceCode}/premium`);
    } else {
      if (
        setRiskTypeError ||
        setPolicyTypeError ||
        setStartDateError ||
        setDurationError ||
        setAmountError
      ) {
        setIsOpenPolicy(true);
      }
      if (
        setRecipientFirstNameError ||
        setRecipientLastNameError ||
        setRecipientNumberError ||
        setRecipientEmailError ||
        setRecipientDateOfBirthError ||
        setRecipientIdTypeError ||
        setRecipientIdNumberError
      ) {
        setIsBuyForSomeone(true);
      }
    }
  };

  const years = Array.from({ length: 30 }, (_, index) => {
    const currentYear = new Date().getFullYear();
    return currentYear - 29 + index;
  });

  const handleBackClick = () => {
    navigate(-1);
  };

  const preventNonNumericInput = (event) => {
    const key = event.key;

    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      ".",
    ];

    const isNumeric = !isNaN(parseInt(key));

    if (!allowedKeys.includes(key) && !isNumeric) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (!["6001", "6006", "6013"].includes(policyType)) {
      setVehicleValue("0");
    }
  }, [policyType]);
  return (
    <>
      {loading ? (
        <div className="jsonForm-loader">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="MotorInsurance">
          <div className="wizard">
            <div className="wizardComponent">
              <div className="CarDetails" style={{ color: "#000" }}>
                <FormattedMessage id="component.details" />
              </div>
              <div className="Premium">
                <FormattedMessage id="component.underwriting" />
              </div>
              <div className="Policy">
                <FormattedMessage id="component.getQuote" />
              </div>
              <div className="Payment">
                <FormattedMessage id="component.payment" />
              </div>
              <div className="Rectangle1"></div>
              <div className="Rectangle2"></div>
              <div className="Rectangle3"></div>
              <div className="Ellipse">
                <div className="EllipsePolicy"></div>
                <div className="EllipsePremium"></div>
                <div className="EllipsePayment"></div>
                <div
                  className="EllipseCarDetails"
                  style={{ background: "#40AD52" }}
                ></div>
              </div>
            </div>
          </div>

          <div className="MotorInsuranceTitle">
            <h3>
              <FormattedMessage id="component.title.policyDetails" />
            </h3>
          </div>

          <form className="form">
            <div className="checkboxToBuyForSomeone">
              <input
                type="checkbox"
                name="buyForSomeoneCheckbox"
                checked={buyForSomeone}
                onChange={(e) => setBuyForSomeone(e.target.checked)}
                className="checkboxStyle"
              />
              <label>
                <FormattedMessage id="component.buyForSomeone" />
              </label>
            </div>

            {buyForSomeone && (
              <div className="collapse-section">
                <div
                  className="collapse-header"
                  onClick={toggleCollapseBuyForSomeone}
                >
                  <FormattedMessage id="component.recipientDetail" />
                  {isBuyForSomeone ? (
                    <span>
                      <FaAngleUp className="arrow" />
                    </span>
                  ) : (
                    <span>
                      <FaAngleDown className="arrow" />
                    </span>
                  )}
                </div>
                {isBuyForSomeone && (
                  <>
                    <div className="formPosition">
                      <label>
                        <FormattedMessage id="component.buyForSomeone.firstName" />
                      </label>
                      <input
                        type="text"
                        className="formStyle"
                        value={recipientFirstName}
                        onChange={(e) => {
                          const value = e.target.value.slice(0, 50);
                          setRecipientFirstName(value);
                        }}
                      />
                      <div className="MotorInsuranceerror">
                        {recipientFirstNameError && (
                          <span className="shake shake1">
                            {recipientFirstNameError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="formPosition">
                      <label>
                        <FormattedMessage id="component.buyForSomeone.lastName" />
                      </label>
                      <input
                        type="text"
                        className="formStyle"
                        value={recipientLastName}
                        onChange={(e) => {
                          const value = e.target.value.slice(0, 50);
                          setRecipientLastName(value);
                        }}
                      />
                      <div className="MotorInsuranceerror">
                        {recipientLastNameError && (
                          <span className="shake shake1">
                            {recipientLastNameError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="formPosition">
                      <label>
                        <FormattedMessage id="component.buyForSomeone.phoneNumber" />
                      </label>
                      <div className="selectContainer">
                        <input
                          type="tel"
                          name=""
                          className="formStyle"
                          placeholder="eg. +233201234567"
                          value={recipientNumber}
                          onChange={(e) => {
                            const value = e.target.value.slice(0, 13);
                            setRecipientNumber(value);
                          }}
                          onKeyDown={(e) => preventNonNumericInput(e)}
                        />
                      </div>
                      <div className="MotorInsuranceerror">
                        {recipientNumberError && (
                          <span className="shake shake1">
                            {recipientNumberError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="formPosition">
                      <label>
                        <FormattedMessage id="component.buyForSomeone.email" />
                      </label>
                      <input
                        type="email"
                        className="formStyle"
                        value={recipientEmail}
                        onChange={(e) => {
                          const value = e.target.value.slice(0, 100);
                          setRecipientEmail(value);
                        }}
                      />
                      <div className="MotorInsuranceerror">
                        {recipientEmailError && (
                          <span className="shake shake1">
                            {recipientEmailError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="datePickersPosition">
                      <div className="formPosition">
                        <label>
                          <FormattedMessage id="component.buyForSomeone.dateOfBirth" />
                        </label>
                        <div className="datePickerContainer">
                          <input
                            type="date"
                            name=""
                            className="datePickersStyle"
                            value={recipientDateOfBirth}
                            onChange={(e) => {
                              const selectedStartDate = e.target.value;
                              setRecipientDateOfBirth(selectedStartDate);
                            }}
                          />
                          <RiCalendar2Line className="datePickerIcon" />
                        </div>
                        <div className="MotorInsuranceerror">
                          {recipientDateOfBirthError && (
                            <span className="shake shake1">
                              {recipientDateOfBirthError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="formPosition">
                        <label>
                          <FormattedMessage id="component.buyForSomeone.idType" />
                        </label>
                        <div className="datePickerContainer">
                          <select
                            className="datePickersStyle"
                            value={recipientIdType}
                            onChange={(e) => {
                              const selectedDuration = e.target.value;
                              setRecipientIdType(selectedDuration);
                            }}
                          >
                            <option value="">
                              <FormattedMessage id="component.buyForSomeone.idType.placeholder" />
                            </option>
                            <option value="Ghana Card">Ghana Card</option>
                            <option value="Passport">Passport</option>
                          </select>
                        </div>

                        <div className="MotorInsuranceerror">
                          {recipientIdTypeError && (
                            <span className="shake shake1">
                              {recipientIdTypeError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="formPosition">
                      <label>
                        <FormattedMessage id="component.buyForSomeone.idNumber" />
                      </label>
                      <input
                        type="text"
                        className="formStyle"
                        value={recipientIdNumber}
                        onChange={(e) => {
                          const value = e.target.value.slice(0, 50);
                          setRecipientIdNumber(value);
                        }}
                      />
                      <div className="MotorInsuranceerror">
                        {recipientIdNumberError && (
                          <span className="shake shake1">
                            {recipientIdNumberError}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            <div className="collapse-section">
              <div className="collapse-header" onClick={toggleCollapseVehicle}>
                <FormattedMessage id="component.vehicleDetail" />
                {isOpenVehicle ? (
                  <span>
                    <FaAngleUp className="arrow" />
                  </span>
                ) : (
                  <span>
                    <FaAngleDown className="arrow" />
                  </span>
                )}
              </div>
              {isOpenVehicle && (
                <>
                  <div className="formPosition">
                    <label>
                      <FormattedMessage id="component.registrationYear" />
                    </label>
                    <div className="datePickerContainer">
                      <select
                        name=""
                        className="formStyle"
                        value={registrationYear}
                        onChange={(e) => {
                          setRegistrationYear(e.target.value);
                        }}
                      >
                        <option value="">
                          <FormattedMessage id="component.registrationYear.option" />
                        </option>
                        {years?.map((year) => (
                          <option
                            value={year}
                            key={year}
                            style={{ padding: 9 }}
                          >
                            {year}
                          </option>
                        ))}
                      </select>
                      <RiCalendar2Line className="datePickerIcon" />
                    </div>
                    <div className="MotorInsuranceerror">
                      {registrationYearError && (
                        <span className="shake shake1">
                          {registrationYearError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="formPosition">
                    <label>
                      <FormattedMessage id="component.vehicleYear" />
                    </label>
                    <div className="datePickerContainer">
                      <select
                        name=""
                        className="formStyle"
                        value={vehicleYear}
                        onChange={(e) => {
                          setVehicleYear(e.target.value);
                        }}
                      >
                        <option value="">
                          <FormattedMessage id="component.vehicleYear.option" />
                        </option>
                        {years?.map((year) => (
                          <option
                            value={year}
                            key={year}
                            style={{ padding: 9 }}
                          >
                            {year}
                          </option>
                        ))}
                      </select>
                      <RiCalendar2Line className="datePickerIcon" />
                    </div>
                    <div className="MotorInsuranceerror">
                      {vehicleYearError && (
                        <span className="shake shake1">{vehicleYearError}</span>
                      )}
                    </div>
                  </div>

                  <div className="datePickersPosition2">
                    <label>
                      <FormattedMessage id="component.engineCapacity" />
                    </label>
                    <div className="engineCapacity">
                      <div>
                        <button
                          onClick={handleLiterClick}
                          style={{
                            border:
                              selectedButton === "liter"
                                ? "1px solid green"
                                : "1px solid #b7bfcb",
                          }}
                        >
                          <FormattedMessage id="component.liter" />
                        </button>
                        <button
                          onClick={handleCubicCapacityClick}
                          style={{
                            border:
                              selectedButton === "cubicCapacity"
                                ? "1px solid green"
                                : "1px solid #b7bfcb",
                          }}
                        >
                          <FormattedMessage id="component.cubicCapacity" />
                        </button>
                      </div>
                      {showLiter && (
                        <div className="formPosition">
                          <input
                            type="number"
                            className="datePickersStyle2"
                            value={liter}
                            placeholder="e.g. 1.5"
                            onChange={(e) => {
                              const value = e.target.value.slice(0, 3);
                              if (value !== "0") {
                                setLiter(value);
                              }
                            }}
                            onKeyDown={(e) => preventNonNumericInput(e)}
                          />
                          <div className="MotorInsuranceerror">
                            {cubicCapacityError && (
                              <span className="shake shake1">
                                {cubicCapacityError}
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      {showCubicCapacity && (
                        <div className="formPosition">
                          <input
                            type="number"
                            className="datePickersStyle2"
                            value={cubicCapacity}
                            placeholder="e.g. 1500"
                            onChange={(e) => {
                              const value = e.target.value.slice(0, 4);
                              if (value !== "0") {
                                setCubicCapacity(value);
                              }
                            }}
                            onKeyDown={(e) => preventNonNumericInput(e)}
                          />
                          <div className="MotorInsuranceerror">
                            {cubicCapacityError && (
                              <span className="shake shake1">
                                {cubicCapacityError}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="formPosition">
                    <label>
                      <FormattedMessage id="component.nOP" />
                    </label>
                    <input
                      type="number"
                      className="formStyle"
                      value={nOP}
                      onChange={(e) => {
                        const value = e.target.value.slice(0, 2);
                        if (value !== "0") {
                          setNOP(value);
                        }
                      }}
                      onKeyDown={(e) => preventNonNumericInput(e)}
                    />
                    <div className="MotorInsuranceerror">
                      {nOPError && (
                        <span className="shake shake1">{nOPError}</span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="collapse-section">
              <div className="collapse-header" onClick={toggleCollapsePolicy}>
                <FormattedMessage id="component.title.coverDetail" />
                {isOpenPolicy ? (
                  <span>
                    <FaAngleUp className="arrow" />
                  </span>
                ) : (
                  <span>
                    <FaAngleDown className="arrow" />
                  </span>
                )}
              </div>
              {isOpenPolicy && (
                <>
                  <div className="formPosition">
                    <label>
                      <FormattedMessage id="component.policyType" />
                    </label>
                    <div className="selectContainer">
                      <select
                        className="formStyle"
                        value={policyType}
                        onChange={(e) => {
                          setPolicyType(e.target.value);
                          const selectedRisk = policy.find(
                            (item) => item.RISK_CODE === e.target.value
                          );
                          setPolicyName(selectedRisk?.RISK_NAME);
                        }}
                      >
                        <option value="">
                          <FormattedMessage id="component.policyType.option" />
                        </option>
                        {policy?.map((item, index) => (
                          <option
                            value={item.RISK_CODE}
                            key={index}
                            style={{ padding: 9 }}
                          >
                            {item.RISK_NAME}
                          </option>
                        ))}
                      </select>
                      <RiArrowDropDownLine className="selectIcon" />
                    </div>
                    <div className="MotorInsuranceerror">
                      {policyTypeError && (
                        <span className="shake shake1">{policyTypeError}</span>
                      )}
                    </div>
                  </div>

                  <div className="formPosition">
                    <label>
                      <FormattedMessage id="component.vehicleUsage" />
                    </label>
                    <div className="selectContainer">
                      <select
                        name=""
                        className="formStyle"
                        value={riskType}
                        onChange={(e) => {
                          setRiskType(e.target.value);
                          const selectedRisk = risk.find(
                            (item) => item.RISK_CODE === e.target.value
                          );
                          setRiskName(selectedRisk?.RISK_NAME);
                        }}
                      >
                        <option value="">
                          <FormattedMessage id="component.vehicleUsage.option" />
                        </option>
                        {risk?.map((item, index) => (
                          <option
                            value={item.RISK_CODE}
                            key={index}
                            style={{ padding: 9 }}
                          >
                            {item.RISK_NAME}
                          </option>
                        ))}
                      </select>
                      <RiArrowDropDownLine className="selectIcon" />
                    </div>
                    <div className="MotorInsuranceerror">
                      {riskTypeError && (
                        <span className="shake shake1">{riskTypeError}</span>
                      )}
                    </div>
                  </div>

                  <div className="datePickersPosition">
                    <div className="formPosition">
                      <label>
                        <FormattedMessage id="component.startDate" />
                      </label>
                      <div className="datePickerContainer">
                        <input
                          type="date"
                          name=""
                          className="datePickersStyle"
                          value={startDate}
                          onChange={(e) => {
                            const selectedStartDate = e.target.value;
                            setStartDate(selectedStartDate);
                            const calculatedEndDate = addMonths(
                              new Date(selectedStartDate),
                              duration
                            );
                            const maxEndDate = addDays(
                              new Date(selectedStartDate),
                              365
                            );
                            setEndDate(
                              calculatedEndDate > maxEndDate
                                ? maxEndDate.toISOString().split("T")[0]
                                : calculatedEndDate.toISOString().split("T")[0]
                            );
                          }}
                        />
                        <RiCalendar2Line className="datePickerIcon" />
                      </div>
                      <div className="MotorInsuranceerror">
                        {startDateError && (
                          <span className="shake shake1">{startDateError}</span>
                        )}
                      </div>
                    </div>
                    <div className="formPosition">
                      <label>
                        <FormattedMessage id="component.duration" />
                      </label>
                      <div className="datePickerContainer">
                        <select
                          className="datePickersStyle"
                          value={duration}
                          onChange={(e) => {
                            const selectedDuration = parseInt(e.target.value);
                            setDuration(selectedDuration);
                            const calculatedEndDate = addMonths(
                              new Date(startDate),
                              selectedDuration
                            );
                            const maxEndDate = addDays(
                              new Date(startDate),
                              365
                            );
                            setEndDate(
                              calculatedEndDate > maxEndDate
                                ? maxEndDate.toISOString().split("T")[0]
                                : calculatedEndDate.toISOString().split("T")[0]
                            );
                          }}
                        >
                          <option value="">
                            <FormattedMessage id="component.duration.option" />
                          </option>
                          <option value={1}>1 Month</option>
                          <option value={2}>2 Months</option>
                          <option value={3}>3 Months</option>
                          <option value={4}>4 Months</option>
                          <option value={5}>5 Months</option>
                          <option value={6}>6 Months</option>
                          <option value={7}>7 Months</option>
                          <option value={8}>8 Months</option>
                          <option value={9}>9 Months</option>
                          <option value={10}>10 Months</option>
                          <option value={11}>11 Months</option>
                          <option value={12}>12 Months</option>
                        </select>
                      </div>

                      <div className="MotorInsuranceerror">
                        {durationError && (
                          <span className="shake shake1">{durationError}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  {["6001", "6006", "6013"].includes(policyType) && (
                    <div className="formPosition">
                      <label>
                        <FormattedMessage id="component.vehicleValue" />
                      </label>
                      <input
                        type="number"
                        name=""
                        className="formStyle"
                        value={vehicleValue}
                        onChange={(e) => {
                          const value = e.target.value.slice(0, 10);
                          if (value === 0) {
                            setVehicleValueError(
                              "Value should be a number greater than 0"
                            );
                          } else {
                            setVehicleValueError("");
                            setVehicleValue(value);
                          }
                        }}
                        onKeyDown={(e) => preventNonNumericInput(e)}
                      />
                      <div className="MotorInsuranceerror">
                        {vehicleValueError && (
                          <span className="">{vehicleValueError}</span>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="checkbox">
                    {!["6002", "6007", "6014"].includes(policyType) && (
                      <div className="checkbocPosition">
                        <input
                          type="checkbox"
                          name="buyBackCheckbox"
                          checked={buyBackExcessEnabled}
                          onChange={(e) =>
                            setBuyBackExcessEnabled(e.target.checked)
                          }
                          className="checkboxStyle"
                        />
                        <label>
                          <FormattedMessage id="component.buyBackExcessEnabled" />
                        </label>
                      </div>
                    )}
                    <div className="checkbocPosition">
                      <input
                        type="checkbox"
                        name="increaseDamageCheckbox"
                        checked={isDamageLimitEnabled}
                        onChange={(e) =>
                          setIsDamageLimitEnabled(e.target.checked)
                        }
                        className="checkboxStyle"
                      />
                      <label>
                        <FormattedMessage id="component.isDamageLimitEnabled" />
                      </label>
                    </div>
                  </div>

                  <div className="checkboxInput">
                    <label>
                      <FormattedMessage id="component.amount" />
                    </label>
                    <input
                      type="number"
                      className="formStyle"
                      name="damageLimitInput"
                      value={amount}
                      onChange={(e) => {
                        const value = e.target.value.slice(0, 10);
                        setAmount(value);
                      }}
                      disabled={!isDamageLimitEnabled}
                      onKeyDown={(e) => preventNonNumericInput(e)}
                    />
                    <div className="MotorInsuranceerror">
                      {amountError && (
                        <span className="shake shake1">{amountError}</span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
          <div className="button">
            <div className="button-double">
              <button className="button-gray" onClick={handleBackClick}>
                <FormattedMessage id="component.button.back" />
              </button>
              <button className="button-green" onClick={generatePremium}>
                <FormattedMessage id="component.button.next" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PolicyDetails;
