
import React, { useState }  from "react";
import Success from "../Success/Success";
import icon from "../../images/icon2.png";
import { ThreeDots } from "react-loader-spinner";
import "./Prompt.css";

const Prompt = ({ momos, holdOn }) => {
  const [suc, setSuc] = useState(false)

  const Done =()=>{
      setSuc(!suc)
  }
  return (
    <div>
      {suc ? (
        <Success momos={momos}/>
      ) : (
        <div className="prompt">
          <div className="momo-header">
            <div className="momo-header-img">
              <img src={momos.logo}></img>
            </div>
            <div className="momo-header-text">
              <p className="font1">{momos.name}</p>
              <p className="font2">{momos.type}</p>
            </div>
          </div>
          <div className="verify-notice">
            <div className="verify-notice-icon">
              <img src={icon} alt="icon" />
            </div>
            <p className="prompt-notice-p1">{momos.authNotice}</p>
            {holdOn ? (
              <div className="hold-on">
                <h6>Please hold on ...</h6>
              </div>
            ) : (
              <p className="prompt-notice-p2">{momos.preAuthNotice}</p>
            )}
          </div>
          <div className="prompt-button">
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
            <p className="font2" onClick={Done}>Verifying</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Prompt;
