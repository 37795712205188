import React, { useEffect, useState } from "react";
import "./VehicleDetails.css";
import { RiArrowDropDownLine } from "react-icons/ri";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Oval } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export const VehicleDetails = () => {
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [bodyType, setBodyType] = useState([]);
  const [loading, setLoading] = useState(false);
  const { serviceCode } = useParams();
  const navigate = useNavigate();
  const [customerCode, setCustomerCode] = useState();

  const storedVinDetails = localStorage.getItem("vinDetails");
  const vinDetails = JSON.parse(storedVinDetails);
  const selectedMake = make?.find((item) => item.NAME === vinDetails?.make);
  const selectedModel = model?.find(
    (item) => item.MMO_NAME === vinDetails?.model
  );

  const storedVehicleDetails = localStorage.getItem("vehicleDetails");
  const vehicleDetails = JSON.parse(storedVehicleDetails);

  const storedRecipientDetails = localStorage.getItem("recipientDetails");
  const recipientDetails = JSON.parse(storedRecipientDetails);

  const storedCustomerDetails = localStorage.getItem("customerDetails");
  const customerDetails = JSON.parse(storedCustomerDetails);

  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleMakeName, setVehicleMakeName] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleModelName, setVehicleModelName] = useState("");
  const [bType, setBType] = useState("");
  const [bName, setBName] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [vehicleMakeError, setVehicleMakeError] = useState("");
  const [vehicleModelError, setVehicleModelError] = useState("");
  const [chassisNumber, setChassisNumber] = useState("");
  const [bTypeError, setBTypeError] = useState("");
  const [registrationNumberError, setRegistrationNumberError] = useState("");
  const [vehicleColorError, setVehicleColorError] = useState("");
  const [chassisNumberError, setChassisNumberError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let uuid = uuidv4();
      try {
        setLoading(true);

        const vehicleMakeResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "vehicleMake",
          async: true,
          params: {},
          url: "/switching/process",
        });

        setMake(vehicleMakeResponse.data.result);

        const bodyTypeResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "bodyType",
          async: true,
          params: {},
          url: "/switching/process",
        });

        setBodyType(bodyTypeResponse.data.result);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let uuid = uuidv4()
      try {
        
        const vehicleModelResponse = await axios.post("/star", {
          requestId:uuid,
          serviceCode: serviceCode,
          method: "vehicleModel",
          async: true,
          params: { productCode: vehicleMake },
          url: "/switching/process",
        });

        setModel(vehicleModelResponse.data.result);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [vehicleMake,vehicleMakeName]);

  useEffect(() => {
    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content =
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";

    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  useEffect(() => {
    if (storedVinDetails || storedVehicleDetails) {
      setVehicleMake(selectedMake?.CODE || vehicleDetails?.vehicleMake || "");
      setVehicleMakeName(selectedMake?.NAME || vehicleDetails?.vehicleMakeName || "");
      setVehicleModel(selectedModel?.MMO_CODE || vehicleDetails?.vehicleModel || "");
      setVehicleModelName(selectedModel?.MMO_NAME || vehicleDetails?.vehicleModelName || "");      
      setBType(vehicleDetails?.bType?vehicleDetails?.bType:"");
      setBName(vehicleDetails?.bName ? vehicleDetails?.bName : "");
      setRegistrationNumber(vehicleDetails?.registrationNumber ?vehicleDetails?.registrationNumber:"");
      setVehicleColor(vehicleDetails?.vehicleColor ? vehicleDetails?.vehicleColor : "");
      setChassisNumber(vehicleDetails?.chassisNumber ? vehicleDetails?.chassisNumber : "");
    }
  }, [selectedMake]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const customerResponse = await axios.post("/getCustomer", {
          accountId: "",
          serviceCode: serviceCode,
          customerId: recipientDetails?.buyForSomeone
          ? recipientDetails?.recipientNumber
          : customerDetails?.phoneNumber,
        });

        if (customerResponse?.data?.data) {
          setCustomerCode(customerResponse?.data?.data?.details?.code);
        } else {
          setCustomerCode("");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [serviceCode]);

  const nextPage = () => {
    let hasError = false;

    if (vehicleMake === "") {
      setVehicleMakeError(<FormattedMessage id="component.vehicleMakeError" />);
      hasError = true;
    } else {
      setVehicleMakeError("");
    }

    if (vehicleModel === "") {
      setVehicleModelError(
        <FormattedMessage id="component.vehicleModelError" />
      );
      hasError = true;
    } else {
      setVehicleModelError("");
    }

    if (bType === "") {
      setBTypeError(<FormattedMessage id="component.bodyTypeError" />);
      hasError = true;
    } else {
      setBTypeError("");
    }

    if (registrationNumber === "") {
      setRegistrationNumberError(
        <FormattedMessage id="component.registrationNumberError" />
      );
      hasError = true;
    } else {
      setRegistrationNumberError("");
    }

    if (vehicleColor === "") {
      setVehicleColorError(
        <FormattedMessage id="component.vehicleColorError" />
      );
      hasError = true;
    } else {
      setVehicleColorError("");
    }
    if (chassisNumber === "" || chassisNumber.length !== 17) {
      setChassisNumberError(
        <FormattedMessage id="component.chassisNumberError" />
      );
      hasError = true;
    } else {
      setChassisNumberError("");
    }

    if (!hasError) {
      const vehicleDetails = {
        vehicleMake: vehicleMake,
        vehicleMakeName: vehicleMakeName,
        vehicleModel: vehicleModel,
        vehicleModelName: vehicleModelName,
        bType: bType,
        bName: bName,
        chassisNumber: chassisNumber,
        registrationNumber: registrationNumber,
        vehicleColor: vehicleColor,
      };
      localStorage.setItem("vehicleDetails", JSON.stringify(vehicleDetails));
      localStorage.setItem("customerCode", JSON.stringify(customerCode));
      navigate(`/vehicle/${serviceCode}/underwriting`);
    }
  };

  const years = Array.from({ length: 30 }, (_, index) => {
    const currentYear = new Date().getFullYear();
    return currentYear - 29 + index;
  });

  const handleBackClick = () => {
    navigate(-1);
  };

  const preventNonNumericInput = (event) => {
    const key = event.key;

    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
    ];

    const isNumeric = !isNaN(parseInt(key));

    if (!allowedKeys.includes(key) && !isNumeric) {
      event.preventDefault();
    }
  };
  return (
    <>
      {loading ? (
        <div className="jsonForm-loader">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="MotorInsurance">
          <div className="wizard">
            <div className="wizardComponent">
              <div className="CarDetails" style={{ color: "#000" }}>
                <FormattedMessage id="component.details" />
              </div>
              <div className="Premium" style={{ color: "#000" }}>
                <FormattedMessage id="component.underwriting" />
              </div>
              <div className="Policy" style={{ color: "#000" }}>
                <FormattedMessage id="component.getQuote" />
              </div>
              <div className="Payment">
                <FormattedMessage id="component.payment" />
              </div>
              <div
                className="Rectangle1"
                style={{ background: "#40AD52" }}
              ></div>
              <div
                className="Rectangle2"
                style={{ background: "#40AD52" }}
              ></div>
              <div className="Rectangle3"></div>
              <div className="Ellipse">
                <div
                  className="EllipsePolicy"
                  style={{ background: "#40AD52" }}
                ></div>
                <div
                  className="EllipsePremium"
                  style={{ background: "#40AD52" }}
                ></div>
                <div className="EllipsePayment"></div>
                <div
                  className="EllipseCarDetails"
                  style={{ background: "#40AD52" }}
                ></div>
              </div>
            </div>
          </div>

          <div className="MotorInsuranceTitle">
            <h3>
              <FormattedMessage id="component.title.underwritingDetails" />
            </h3>
          </div>

          <form className="form">
            <div className="formPosition">
              <label>
                <FormattedMessage id="component.vehicleMake" />
              </label>
              <div className="selectContainer">
                <select
                  name=""
                  className="formStyle"
                  value={vehicleMake}
                  onChange={(e) => {
                    setVehicleMake(e.target.value);
                    const selectedRisk = make.find(
                      (item) => item.CODE === e.target.value
                    );
                    setVehicleMakeName(selectedRisk?.NAME);
                  }}
                >
                  <option value="">
                    <FormattedMessage id="component.vehicleMake.option" />
                  </option>
                  {make?.map((item, index) => (
                    <option
                      value={item.CODE}
                      key={index}
                      style={{ padding: 9 }}
                    >
                      {item.NAME}
                    </option>
                  ))}
                </select>
                <RiArrowDropDownLine className="selectIcon" />
              </div>
              <div className="MotorInsuranceerror">
                {vehicleMakeError && (
                  <span className="shake shake1">{vehicleMakeError}</span>
                )}
              </div>
            </div>

            <div className="formPosition">
              <label>
                <FormattedMessage id="component.vehicleModel" />
              </label>
              <div className="selectContainer">
                <select
                  name=""
                  className="formStyle"
                  value={vehicleModel}
                  onChange={(e) => {
                    setVehicleModel(e.target.value);
                    const selectedRisk = model.find(
                      (item) => item.MMO_CODE === e.target.value
                    );
                    setVehicleModelName(selectedRisk?.MMO_NAME);
                  }}
                >
                  <option value="">
                    <FormattedMessage id="component.vehicleModel.option" />
                  </option>
                  {model?.map((item, index) => (
                    <option
                      value={item.MMO_CODE}
                      key={index}
                      style={{ padding: 9 }}
                    >
                      {item.MMO_NAME}
                    </option>
                  ))}
                </select>
                <RiArrowDropDownLine className="selectIcon" />
              </div>
              <div className="MotorInsuranceerror">
                {vehicleModelError && (
                  <span className="shake shake1">{vehicleModelError}</span>
                )}
              </div>
            </div>

            <div className="datePickersPosition">
              <div className="formPosition">
                <label>
                  <FormattedMessage id="component.bodyType" />
                </label>
                <div className="selectContainer">
                  <select
                    name=""
                    className="datePickersStyle"
                    value={bType}
                    onChange={(e) => {
                      setBType(e.target.value);
                      const selectedRisk = bodyType?.data?.find(
                        (item) => item.CODE === e.target.value
                      );
                      setBName(selectedRisk?.NAME);
                    }}
                  >
                    <option value="">
                      <FormattedMessage id="component.bodyType.option" />
                    </option>
                    {bodyType?.data?.map((item, index) => (
                      <option
                        value={item.CODE}
                        key={index}
                        style={{ padding: 9 }}
                      >
                        {item.NAME}
                      </option>
                    ))}
                  </select>
                  <RiArrowDropDownLine className="selectIcon" />
                </div>
                <div className="MotorInsuranceerror">
                  {bTypeError && (
                    <span className="shake shake1">{bTypeError}</span>
                  )}
                </div>
              </div>
              <div className="formPosition">
                <label>
                  <FormattedMessage id="component.chassisNumber" />
                </label>
                <input
                  type="text"
                  className="datePickersStyle"
                  value={chassisNumber}
                  onChange={(e) => {
                    const value = e.target.value.slice(0, 17).toUpperCase();
                    setChassisNumber(value);
                  }}
                />
                <div className="MotorInsuranceerror">
                  {chassisNumberError && (
                    <span className="shake shake1">{chassisNumberError}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="datePickersPosition">
              <div className="formPosition">
                <label>
                  <FormattedMessage id="component.registrationNumber" />
                </label>
                <input
                  type="text"
                  className="datePickersStyle"
                  value={registrationNumber}
                  placeholder="eg. AB 1234-56"
                  onChange={(e) => {
                    const value = e.target.value.slice(0, 10);
                    setRegistrationNumber(value);
                  }}
                />
                <div className="MotorInsuranceerror">
                  {registrationNumberError && (
                    <span className="shake shake1">
                      {registrationNumberError}
                    </span>
                  )}
                </div>
              </div>
              <div className="formPosition">
                <label>
                  <FormattedMessage id="component.vehicleColor" />
                </label>
                <input
                  type="text"
                  name=""
                  className="datePickersStyle"
                  value={vehicleColor}
                  onChange={(e) => {
                    const value = e.target.value.slice(0, 20);
                    setVehicleColor(value);
                  }}
                />
                <div className="MotorInsuranceerror">
                  {vehicleColorError && (
                    <span className="">{vehicleColorError}</span>
                  )}
                </div>
              </div>
            </div>
          </form>
          <div className="button">
            <div className="button-double">
              <button className="button-gray" onClick={handleBackClick}>
                <FormattedMessage id="component.button.back" />
              </button>
              <button className="button-green" onClick={nextPage}>
                <FormattedMessage id="component.button.next" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
