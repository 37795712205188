

import React, { useState, useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import LUPICD from "./LUPICD";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Vortex } from "react-loader-spinner";

export default function LUPI({ merchants }) {
  const params = useParams();
  const param = parseInt(params.serviceCode);

  const [refnum, setRefnum] = useState("");
  const [inputError, setInputError] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState(false);
  const [lookUppp, setLookUppp] = useState("");

  const handleShow = async (e) => {
    try {
      e.preventDefault();
      setSearch(true);

      if (refnum === "") {
        setInputError("Please input a reference");
      } else {
        const dat = await axios
          .post(
            "core",
            {
              serviceCode: param,
              reference: refnum,
              verbose: true,
              url: "/billing/lookup",
              metaData: [
                {
                  key: "field1",
                  value: 1701187235,
                },
              ],
            },
            {
              auth: {
                username: "stanbic",
                password: "password",
              },
            }
          )

          .then((res) => {
            const merchantDetails = res.data.data;
            setLookUppp(merchantDetails);
            console.log(merchantDetails, "this is the school data");
          });
      }
      setSearch(false);
      setConfirmed(true);
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    handleShow();
  }, []);
  return (
    <div>
      <div className="LUP">
        <form className="LUP-form">
          <input
            type="text"
            placeholder={merchants.referenceField}
            className="LUP-input"
            value={refnum}
            onChange={(e) => setRefnum(e.target.value)}
          />
          <button
            className="LUP-button"
            disabled={!refnum}
            onClick={handleShow}
          >
            {search ? (
              <Vortex
                visible={true}
                height="40"
                width="40"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={["green", "green", "green", "green", "green", "green"]}
              />
            ) : (
              <BiSearch className="LUP-button-icon"/>
            )}
          </button>
        </form>
      </div>
      {confirmed && (
        <LUPICD
          isConfirmed={setConfirmed}
          lookUppp={lookUppp}
          merchants={merchants}
          refnum={refnum}
        />
        // <div>king</div>
      )}
    </div>
  );
}
