import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Modal.css";
import { useNavigate, useParams } from "react-router-dom";




const Modal = ({ open, onClose,invoiceData}) => {
  const [cancelInvoice, setCancelInvoice] = useState("");
  const navigate = useNavigate();

  let params = useParams();
  const invoiceNum =invoiceData.invoiceNum;
  const cancel = async (e) => {
    try {
      e.preventDefault();
      const response= await axios
        .post("pos", {
          requestId: "{{$guid}}",
          username: "stanbic",
          password: "password",
          invoiceNum: invoiceNum,
          url: "/webpos/cancelInvoice",
        })
      
          setCancelInvoice(response.data.result);
          navigate(`/${invoiceData.serviceCode}`)
     
    } catch (error) {
 
    }
  };



  if (!open) return null;
  return (
          <div className="modal">
            <div className="modal-container">
              <p className="modal-container-close" onClick={onClose}>
                X
              </p>
              <div className="modal-container-header">
                Are you sure you would like to cancel this order?
              </div>
              <div className="modal-container-buttons">
                <button className="modal-cancel-no" onClick={onClose}>
                  No
                </button>
                <button className="modal-cancel-yes" onClick={cancel}>
                  Yes
                </button>
              </div>
            </div>
          </div>
  );
};

export default Modal;
