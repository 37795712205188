
import React, { useState, useEffect } from "react";
import { Form, Container, Card } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Vortex } from "react-loader-spinner";

const ListItems = (props) => {
  return (
    <div className="payoption-item">
      <div className="payoption-list">
        <input
          inline="true"
          name="group1"
          type="checkbox"
          id="2"
          value={props.price}
          className="payoption-list-radio"
          onChange={props.select}
          checked={props.setIsChecked(props.itemCode)}
        />

        <div className="payoption-list-data">
          <p className="payoption-list-data-name">{props.name}</p>
          <p className="payoption-list-data-amount"> GHS {props.price}</p>
        </div>
      </div>
      <div className="payoption-line" />
    </div>
  );
};

export default function LUPICD({ lookUppp, merchants, refnum }) {
  let params = useParams();
  const navigate = useNavigate();
  const param = params.serviceCode;

  const [checked, setChecked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [totalBill, setTotalBill] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);

  const totals = lookUppp.billItems;
  console.log(totalBill,"money")

  const select = (e) => {
    setChecked(!checked);
    setTotalBill(e.target.value);
  };

  const [invoiceData, setInvoiceData] = useState("");

  const pay = async (e) => {
    try {
      e.preventDefault();
      setSearchLoader(true);
      const dat = await axios
        .post("pos", {
          requestId: "4d55391b-f463-4df7-8918-578d8eec94b5",
          username: "stanbic",
          password: "password",
          merchantOrderId: "202008241855",
          serviceCode: param,
          currency: "GHS",
          amount: totalBill,
          reference: refnum,
          url: "/webpos/createInvoice",
        })
        .then((res) => {
          const invoiceCreation = res.data.result;
          setInvoiceData(invoiceCreation);
          console.log(invoiceCreation, "invoice data");
          // navigate("/checkout", {
          //   state: {
          //     merchant: merchants,
          //     invoice: invoiceCreation,
          //     refnum: refnum,
          //   },
          // });
          const invoiceNum=invoiceCreation.invoiceNum
          navigate(`/checkout/${invoiceNum}`, {
            state: {
              merchant: merchants,
              invoice: invoiceCreation,
              refnum: refnum,
            },
          });
        });
      setSearchLoader(false);
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    pay();
  }, []);

  return (
    <div>
      <div className="LUPACD-container">
        <div className="LUPACD-container-fold">
          <div className="LUPACD-fold-item">
            <p className="font1"> Student Name</p>
            <p className="font2"> {lookUppp.customerName}</p>
          </div>
          <div className="LUPACD-fold-item1">
            <p className="font1"> Description</p>
            <p className="font2"> {lookUppp.description}</p>
          </div>
        </div>
      </div>
      <div fluid className="payoption-scroll">
        {totals.map(({ itemCode, itemName, price }) => (
          <ListItems
            key={itemCode}
            price={price}
            name={itemName}
            itemCode={itemCode}
            setIsChecked={setIsChecked}
            select={select}
          />
        ))}
      </div>

      <div className="payflow-form-total">
        <h6>Total : GHS {checked ? totalBill : 0.0}</h6>
      </div>
      <button className="payflow-form-button" disabled={!checked} onClick={pay}>
        Continue To Pay
        {searchLoader && (
          <Vortex
            visible={true}
            height="40"
            width="40"
            ariaLabel="vortex-loading"
            wrapperStyle={{}}
            wrapperClass="vortex-wrapper"
            colors={["white", "white", "white", "white", "white", "white"]}
          />
        )}
      </button>
    </div>
  );
}
