
import React, { useState, useEffect } from "react";
import CheckoutInput from "../CheckoutInput/CheckoutInput";
import Prompt from "../Prompt/Prompt";
import icon from "../../images/icon1.png";
import { Vortex } from "react-loader-spinner";
import axios from "axios";
import "./Verify.css";

const Verify = ({
  phoneNumber,
  invoiceData,
  errors,
  processPayment,
  holdOn,
  momos,
  setCurrent
}) => {
  const [prev, setPrev] = useState(false);
  const [process, setProcess] = useState(false);
  const [debAuth, setDebAuth] = useState([]);

  // const debit = (e) => {
  //   e.preventDefault();
  //   setProcess(!process);
  // };

  const debitAuth = async (e) => {
    try {
      e.preventDefault();
      setProcess(!process);
      const dat = await axios
        .post("pos", {
          requestId: "{{$guid}}",
          username: "stanbic",
          password: "password",
          reference: phoneNumber,
          url: "/webpos/validateDebitAuth",
        })
        .then((res) => {
          const deAuthh = res.data;
          setDebAuth(deAuthh);
          console.log(deAuthh, "Validate Debit");
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    debitAuth();
    setCurrent(2)
  }, []);

  return (
    <div>
      {process ? (
        <Prompt momos={momos} debAuth={debAuth} holdOn={holdOn} />
      ) : (
        <>
          <div className="verify">
            <div className="momo-header">
              <div className="momo-header-img">
                <img src={momos.logo}></img>
              </div>
              <div className="momo-header-text">
                <p className="font1">{momos.name}</p>
                <p className="font2">{momos.type}</p>
              </div>
            </div>
            <div className="verify-notice">
              <div className="verify-notice-icon">
                <img src={icon} alt="icon" />
              </div>
              <p className="verify-notice-p1">
                Please verify Mobile Money number to complete payment
              </p>
              {holdOn ? (
                <div className="verify-spin">
                  <Vortex
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="vortex-loading"
                    wrapperStyle={{}}
                    wrapperClass="vortex-wrapper"
                    colors={[
                      "green",
                      "green",
                      "green",
                      "green",
                      "green",
                      "green",
                    ]}
                  />
                  <h6>Please hold on ...</h6>
                </div>
              ) : (
                <p className="verify-notice-p2">{processPayment.statusMessage}</p>
              )}
            </div>
            <div className="verify-button">
              <button onClick={debitAuth}> Proceed to payment </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Verify;
