import React, { useEffect, useState } from "react";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import "./circles.css";
import { Oval } from "react-loader-spinner";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";

const JsonForm = () => {
  const [loading, setLoading] = useState(true);
  const [schemaData, setSchemaData] = useState("");
  const [allSchemaData, setAllSchemaData] = useState("");

  const { circleCode } = useParams();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const customerFirstName = urlParams.get("firstName");
  const customerLastName = urlParams.get("lastName");
  const customerOtherName = urlParams.get("otherName");
  const customerPhoneNumber = urlParams.get("PhoneNumber");
  const customerEmail = urlParams.get("email");
  const customerId = urlParams.get("customerId");
  const customerFormName = urlParams.get("formName");
  const [formName,setFormName]=useState(customerFormName)
  const customerCircleCode = circleCode;

  const [data, setData] = useState({});
  const [emailValue, setEmailValue] = useState(customerEmail);

  const customRenderers = [...materialRenderers];

  const [selectedField, setSelectedField] = useState(null);
  const handleFieldSelection = (fieldName) => {
    setSelectedField(fieldName);
  };

  useEffect(() => {
    const joinCircleConditionally = async () => {
      if (formName !== "") {
        try {
          setLoading(true);
          const schema = await axios.post("/circle", {
            name: formName,
          });
          if (schema.data.status === false) {
            navigate(`/circles/${customerCircleCode}/error`);
          } else {
            if (schema.data.data.schema === null || schema.data.data.schema === "" ) {
              setFormName("");
            } else {
              setAllSchemaData(JSON.parse(schema.data.data.schema));
              const { $schema, required, ...rest } = JSON.parse(
                schema.data.data.schema
              );
              setSchemaData(rest);
            }
          }

          setLoading(false);
        } catch (error) {
          console.error("Error joining circle:", error);
          navigate(`/circles/${customerCircleCode}/error`);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    joinCircleConditionally();
  }, []);

  const checkRequiredFields = () => {
    const requiredFields = allSchemaData.required || [];

    for (const fieldName of requiredFields) {
      if (!data[fieldName]) {
        return true;
      }
    }

    return false;
  };

  const handleCircleJoin = async () => {
    setLoading(true);
    try {
      if (checkRequiredFields()) {
        toast.warn("Please fill in all required fields before submitting.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      
      const joinCircle = await axios.post("/star", {
        circleCode: customerCircleCode,
        customer: {
          customerEmail: emailValue,
          customerId: customerId,
          firstName: customerFirstName,
          lastName: customerLastName,
          otherNames: customerOtherName,
          customerName: `${customerFirstName} ${customerLastName} ${
            customerOtherName || ""
          }`,
          customerPhoneNo: customerPhoneNumber,
          details: data,
        },
        url: "/api/rest/circle/customer/join",
      });

      if (joinCircle.data.status === true) {
        navigate(`/circles/${customerCircleCode}/success`);
      } else {
        navigate(`/circles/${customerCircleCode}/error`);
      }
    } catch (error) {
      console.error("Error joining circle:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="jsonForm-loader">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
          <p className="joiningCircle">
            <FormattedMessage id="component.circle.loadingMessage" />
          </p>
        </div>
      ) : (
        <div className="circle-form">
          <div className="circle-form-content">
            <h3 className="form-title">
              <FormattedMessage id="component.circle.title" />
            </h3>
            <div style={{ marginTop: "20px" }}>
              <TextField
                disabled
                id="standard-required"
                label="Full Name"
                defaultValue={`${customerFirstName} ${customerLastName} ${
                  customerOtherName || ""
                }`}
                variant="standard"
                fullWidth
                className="MuiGrid-item"
              />
              <TextField
                disabled
                id="standard-required"
                label="Phone Number"
                defaultValue={customerPhoneNumber}
                variant="standard"
                fullWidth
                className="MuiGrid-item"
              />
              <TextField
                id="standard-required"
                label="Email"
                defaultValue={customerEmail}
                variant="standard"
                fullWidth
                className="MuiGrid-item"
                onChange={(event) => {
                  setEmailValue(event.target.value);
                }}
              />
            </div>
            {schemaData && (
              <p>
                <JsonForms
                  schema={schemaData}
                  data={data}
                  renderers={customRenderers}
                  cells={materialCells}
                  onChange={({ errors, data }) => {
                    setData(data);
                  }}
                  onFocus={({ dataPath }) => handleFieldSelection(dataPath)}
                  onBlur={() => setSelectedField(null)}
                  className={selectedField ? "selected-field" : ""}
                />
              </p>
            )}
          </div>
          <div className="fixed-button">
            <button
              className="circle-btn"
              onClick={handleCircleJoin}
            >
              <FormattedMessage id="component.circle.submitButton" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default JsonForm;
