
import React from "react";
import './Success.css'

const Success = ({momos}) => {
  return (
    <div className="success">
      <div className="momo-header">
        <div className="momo-header-img">
          <img src={momos.logo}></img>
        </div>
        <div className="momo-header-text">
          <p className="font1">{momos.name}</p>
          <p className="font2">{momos.type}</p>
        </div>
      </div>

      <div className="done">
        <div className="done-image">
          <img src="{logo}" alt="" />
        </div>
        <div className="done-text">
          <p className="font1">Thank you for payment</p>
          <p className="font2">
            MTN airtime of GHS 550 from MTN Mobile Money 0591686512{" "}
          </p>
        </div>
        <div className="done-table">
          <div className="done-table-row">
            <p className="font2">Status</p>
            <p className="font3">Confirmed</p>
          </div>
          <div className="done-table-row">
            <p className="font2">Payment Ref</p>
            <p className="font3">Momo 6512</p>
          </div>
          <div className="done-table-row">
            <p className="font2">Trans ID</p>
            <p className="font3">235GK5M8</p>
          </div>
        </div>
      </div>

      <div className="checkoutInput-buttons">
        <a onClick={""}>
          <span>Done</span>
        </a>
        <button onClick={""}> Print reciept </button>
      </div>
    </div>
  );
};

export default Success;
