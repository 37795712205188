
import React from "react";
import Verify from "../Verify/Verify";
import Prompt from "../Prompt/Prompt";

const CheckoutInput = ({
  invoiceData,
  invoice,
  momos,
  setCurrent,
  processPayment,
  phoneNumber,
  errors,
  holdOn,
}) => {
  let nextType = processPayment.statusCode;
  const codeee = () => {
    switch (nextType) {
      case 
      "AUTHENTICATION_REQUIRED":
        return (
          <Verify
            invoiceData={invoiceData}
            phoneNumber={phoneNumber}
            errors={errors}
            processPayment={processPayment}
            holdOn={holdOn}
            momos={momos}
            setCurrent={setCurrent}
          />
        );

      case "SUCCESS":
        return <Prompt momos={momos}  holdOn={holdOn}/>;
    }
  };

  return <div>{codeee()}</div>;
};

export default CheckoutInput;
