import React from "react";
import travelComingSoon from "../../images/policyComingSoon.png";
import "./PolicyComingSoon.css";

const PolicyComingSoon = () => {
  return (
    <div className="ComingSoon">
     
        <img src={travelComingSoon} />
 
      <h6>Unavailable</h6>
      <p>The feature is currently unavailable.</p>
      <p>We are working on it.</p>
    </div>
  );
};

export default PolicyComingSoon;
