import React, { useEffect, useState } from "react";
import "./TravelPremium.css";
import { date } from "../../../utils/dateformat";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Oval } from "react-loader-spinner";
import { useParams, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const TravelPremium = () => {
  const [travelPremium, setTravelPremium] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { serviceCode } = useParams();

  const storedCustomerDetails = localStorage.getItem("customerDetails");
  const customerDetails = JSON.parse(storedCustomerDetails);

  const storedTravelDetails = localStorage.getItem("travelDetails");
  const travelDetails = JSON.parse(storedTravelDetails);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let uuid = uuidv4();
      try {
        const premiumResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "travelPremiumGeneration",
          async: true,
          params: {
            customerName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
            customerEmail: customerDetails?.email,
            customerPhoneNumber: customerDetails?.PhoneNumber,
            coverage: travelDetails?.coverage,
            days: travelDetails?.days.toString(),
            countryCode: travelDetails?.destinationCountryCode,
            countryName: travelDetails?.destinationCountryName,
            age: travelDetails?.age.toString(),
          },
          url: "/switching/process",
        });

        setTravelPremium(premiumResponse.data.result);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const premiumData = travelPremium?.data;

  const handlePremiumRetry = async () => {
    setLoading(true);
    let uuid = uuidv4();
    try {
      const premiumResponse = await axios.post("/star", {
        requestId: uuid,
        serviceCode: serviceCode,
        method: "travelPremiumGeneration",
        async: true,
        params: {
          customerName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
          customerEmail: customerDetails?.email,
          customerPhoneNumber: customerDetails?.PhoneNumber,
          coverage: travelDetails?.coverage,
          days: travelDetails?.days,
          countryCode: travelDetails?.destinationCountryCode,
          countryName: travelDetails?.destinationCountryName,
          age: travelDetails?.age,
        },
        url: "/switching/process",
      });


      setTravelPremium(premiumResponse.data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClick = () => {
    const travelPremiumDetails = {
      travelPremium: travelPremium,
    };
    localStorage.setItem("travelPremiumDetails", JSON.stringify(travelPremiumDetails));
    navigate(`/travel/${serviceCode}/travelPolicyDetails`);
  };
  const handleBackClick = () => {
    navigate(-1);
  };
  const handleCancel = () => {
    navigate(`/redirect/${serviceCode}/error`);
  };
  return (
    <>
      {loading ? (
       <div className="jsonForm-loader">
       <Oval
         visible={true}
         height="40"
         width="40"
         color="#4fa94d"
         ariaLabel="oval-loading"
         wrapperStyle={{}}
         wrapperClass=""
       />
        </div>
      ) : (
        <div className="MotorInsurance">
      <div className="wizard">
        <div className="wizardComponent">
          <div className="TravelDetails" style={{ color: "#000" }}>
          <FormattedMessage id="component.travel.travelDetails"/>
          </div>
          <div className="CustomerDetails"><FormattedMessage id="component.travel.policyDetails"/></div>
          <div className="Premium2"  style={{ color: "#000" }}><FormattedMessage id="component.getQuote"/></div>
          <div className="Payment"><FormattedMessage id="component.payment"/></div>
          <div className="Rectangle1" style={{background: '#40AD52'}}></div>
          <div className="Rectangle2"></div>
          <div className="Rectangle3"></div>
          <div className="Ellipse">
            <div className="EllipsePremium"  ></div>
            <div className="EllipseCustomerDetails"style={{ background: "#40AD52" }}></div>
            <div className="EllipsePayment"></div>
            <div
              className="EllipseTravelDetails"
              style={{ background: "#40AD52" }}
            ></div>
          </div>
        </div>
      </div>

          <div className="MotorInsuranceTitle">
            <h3><FormattedMessage id="component.getQuote"/></h3>
          </div>
          {premiumData ? (
            <>
              <div className="premium">
                <div className="premium-card">
                  <div>
                    <h5><FormattedMessage id="component.travel.premiumTotal"/></h5>
                    <h4>GHS {premiumData?.TotalPremium}</h4>
                  </div>
                  <div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.travel.destination"/></p>
                      <p className="premium-col2">
                        {premiumData?.travel?.countryName
                          ? premiumData?.travel?.countryName
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1">
                      <FormattedMessage id="component.travel.coverage"/>
                      </p>
                      <p className="premium-col2">
                        {premiumData?.travel?.coverage
                          ? premiumData?.travel?.coverage
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1">
                      <FormattedMessage id="component.travel.Days"/>
                      </p>
                      <p className="premium-col2">
                        {premiumData?.travel?.days
                          ? premiumData?.travel?.days
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.travel.travelDate"/></p>
                      <p className="premium-col2">
                        {date(travelDetails?.travelDate)}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.travel.returnDate"/></p>
                      <p className="premium-col2">
                        {date(travelDetails?.returnDate)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button">
                <div className="button-double">
                  <button className="button-gray" onClick={handleBackClick}>
                  <FormattedMessage id="component.button.back"/>
                  </button>
                  <button
                    className="button-green"
                    onClick={() => handleClick()}
                  >
                    <FormattedMessage id="component.button.next"/>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="modal">
              <div className="modal-content">
                <h3><FormattedMessage id="component.premiumCreationFailed"/></h3>
                <p>
                <FormattedMessage id="component.premiumCreationFailed.message"/>
                </p>
                <div>
                  <button
                    className="button-modal-green"
                    onClick={handlePremiumRetry}
                  >
                    <FormattedMessage id="component.button.retry"/>
                  </button>
                  <button className="button-modal-gray" onClick={handleCancel}>
                  <FormattedMessage id="component.button.cancel"/>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TravelPremium;
