import React, { useEffect, useState } from "react";
import { Oval} from "react-loader-spinner";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import "./PayPremium.css";
import {v4 as uuidv4} from 'uuid';
import { useNavigate, useParams } from "react-router-dom";
import { date } from "../../../utils/dateformat";
import { CopyOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { FaChevronCircleDown,FaChevronCircleUp } from "react-icons/fa";

export const PayPremium = () => {
  const [loading, setLoading] = useState(false);
  const [underwriting, setUnderwriting] = useState("");
  const [customer, setCustomer] = useState("");
    
  const [isDetailsVisible, setDetailsVisible] = useState(false);

  const { serviceCode } = useParams();
  const navigate = useNavigate();

  const storedCustomerDetails = localStorage.getItem("customerDetails");
  const customerDetails = JSON.parse(storedCustomerDetails);

  const storedcustomerCode = localStorage.getItem("customerCode");
  const customerCode = JSON.parse(storedcustomerCode);

  const storedRecipientDetails = localStorage.getItem("recipientDetails");
const recipientDetails = JSON.parse(storedRecipientDetails);

  const storedVehicleDetails = localStorage.getItem("vehicleDetails");
  const vehicleDetails = JSON.parse(storedVehicleDetails);

  const storedPolicyDetails = localStorage.getItem("policyDetails");
  const policyDetails = JSON.parse(storedPolicyDetails);

  const storedPremiumDetails = localStorage.getItem("premiumDetails");
  const premiumDetails = JSON.parse(storedPremiumDetails);

  const storedcustomerCodeDetail = localStorage.getItem("customerCodeDetail");
  const customerCodeDetail = JSON.parse(storedcustomerCodeDetail);

  const generatePremiumDetails = premiumDetails?.premium?.data?.vehicles[0];
  const currencyRate = premiumDetails?.premium?.data?.currencyRate;
  const currency = premiumDetails?.premium?.data?.currency;

  const numberWithCommas = (number) => {
    return number.toLocaleString(); 
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let uuid =  uuidv4();
      try {
        let finalCustomerCode = customerCode;

        if (!customerCode) {
          const customerResponse = await axios.post("/star", {
            requestId: uuid,
            serviceCode: serviceCode,
            method: "createCustomer",
            async: true,
            params: {
              firstName: recipientDetails?.buyForSomeone
                ? recipientDetails?.recipientFirstName
                : customerDetails?.firstName,
              lastName: recipientDetails?.buyForSomeone
                ? recipientDetails?.recipientLastName
                : customerDetails?.lastName,
              PhoneNumber: recipientDetails?.buyForSomeone
                ? recipientDetails?.recipientNumber
                : customerDetails?.phoneNumber,
              email: recipientDetails?.buyForSomeone
                ? recipientDetails?.recipientEmail
                : customerDetails?.email,
              dateOfBirth: recipientDetails?.buyForSomeone
                ? recipientDetails?.recipientDateOfBirth
                : customerDetails?.dateOfBirth,
              identificationNumber: recipientDetails?.buyForSomeone
                ? recipientDetails?.recipientIdNumber
                : customerDetails?.identificationNumber,
              identificationType: recipientDetails?.buyForSomeone
                ? recipientDetails?.recipientIdType
                : customerDetails?.identificationType,
              gender: customerDetails?.gender,
            },
            url: "/switching/process",
          });
  
          setCustomer(customerResponse?.data?.result?.data);
  
          const customerCodeDetail = {
            customerCode: customerResponse?.data?.result?.data?.code,
          };
          localStorage.removeItem("customerCodeDetail");
          localStorage.setItem(
            "customerCodeDetail",
            JSON.stringify(customerCodeDetail)
          );
          finalCustomerCode = customerCodeDetail?.customerCode
        }

        const underwritingResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "motorPolicyUnderwriting",
          async: true,
          params: {
            customerCode: finalCustomerCode,
            intermediaryCode: "GA00912",
            insuranceSource: "DIR",
            businessSource: "AGM",
            startDate: policyDetails?.startDate,
            endDate: policyDetails?.endDate,
            policyDays: policyDetails?.policyDays.toString(),
            periodDays: policyDetails?.policyDays.toString(),
            underwritingYear: underwritingYear.toString(),
            sumInsured: generatePremiumDetails?.sumInsured.toString(),
            premium: generatePremiumDetails?.netPremium.toString(),
            state: "S",
            policyNumber: "",
            policyType: "1",
            status: "U",
            classCode: "6000",
            customerName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
            customerAddress: "",
            customerPhoneNumber: customerDetails?.PhoneNumber,
            premiumBasis: "SP",
            motorRiskStartDate: policyDetails?.startDate,
            motorRiskEndDate: policyDetails?.endDate,
            riskName: policyDetails?.riskType,
            motorRiskUnderwritingYear: underwritingYear.toString(),
            modelCode: vehicleDetails?.vehicleModel,
            modelName: vehicleDetails?.vehicleModelName,
            makeCode: vehicleDetails?.vehicleMake,
            makeName: vehicleDetails?.vehicleMakeName,
            usageCode: "1001",
            vehicleRegistrationNumber: vehicleDetails?.registrationNumber,
            bodyTypeCode: vehicleDetails?.bType,
            bodyType: vehicleDetails?.bName,
            manufactureYear: policyDetails?.vehicleYear,
            chassisNumber: vehicleDetails?.chassisNumber,
            cubicCapacity: generatePremiumDetails?.cubicCapacity.toString(),
            numberOfSeats: generatePremiumDetails?.passengers.toString(),
            excessApplicableYn: generatePremiumDetails?.buyBackExcess.toString(),
            extraTppdYn: tppdYn,
            vehicleValue: generatePremiumDetails?.vehicleValue.toString(),
            motorRiskCreatedBy: "",
            riskCode: generatePremiumDetails?.risk,
            color: vehicleDetails?.vehicleColor,
            tppdYn: tppdYn,
            tppdValue: generatePremiumDetails?.extraTppdAmount.toString(),
            reInsurancePremium: generatePremiumDetails?.riPremium.toString(),
            reInsuranceSumInsured: generatePremiumDetails?.riSumInsured.toString(),
            riskPremium: generatePremiumDetails?.riskPremium.toString(),
            fees: "0",
            registrationYear: policyDetails?.registrationYear,
            currencyRate:currencyRate.toString(),
            currency: currency,
            product: policyDetails?.policyType,
          },
          url: "/switching/process",
        });

        setUnderwriting(underwritingResponse.data.result);

        setLoading(false);
      } catch (error) {

        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const underwritingDetails = underwriting?.data;

  const year = new Date(policyDetails?.startDate);
  const underwritingYear = year.getUTCFullYear();
  const tppdYn = generatePremiumDetails?.buyBackExcess?"Y":"N"

  const handleCustomerRetry = async () => {
    setLoading(true);
    let uuid =  uuidv4();
    try {
      let finalCustomerCode = customerCode;
      if (!customerCode) {
        const customerResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "createCustomer",
          async: true,
          params: {
            firstName: recipientDetails?.buyForSomeone
              ? recipientDetails?.recipientFirstName
              : customerDetails?.firstName,
            lastName: recipientDetails?.buyForSomeone
              ? recipientDetails?.recipientLastName
              : customerDetails?.lastName,
            PhoneNumber: recipientDetails?.buyForSomeone
              ? recipientDetails?.recipientNumber
              : customerDetails?.phoneNumber,
            email: recipientDetails?.buyForSomeone
              ? recipientDetails?.recipientEmail
              : customerDetails?.email,
            dateOfBirth: recipientDetails?.buyForSomeone
              ? recipientDetails?.recipientDateOfBirth
              : customerDetails?.dateOfBirth,
            identificationNumber: recipientDetails?.buyForSomeone
              ? recipientDetails?.recipientIdNumber
              : customerDetails?.identificationNumber,
            identificationType: recipientDetails?.buyForSomeone
              ? recipientDetails?.recipientIdType
              : customerDetails?.identificationType,
            gender: customerDetails?.gender,
          },
          url: "/switching/process",
        });

        setCustomer(customerResponse?.data?.result?.data);

        const customerCodeDetail = {
          customerCode: customerResponse?.data?.result?.data?.code,
        };
        localStorage.removeItem("customerCodeDetail");
        localStorage.setItem(
          "customerCodeDetail",
          JSON.stringify(customerCodeDetail)
        );
        finalCustomerCode = customerCodeDetail?.customerCode
      }

      const underwritingResponse = await axios.post("/star", {
        requestId: uuid,
        serviceCode: serviceCode,
        method: "motorPolicyUnderwriting",
        async: true,
        params: {
          customerCode:  finalCustomerCode,
          intermediaryCode: "GA00912",
          insuranceSource: "DIR",
          businessSource: "AGM",
          startDate: policyDetails?.startDate,
          endDate: policyDetails?.endDate,
          policyDays: policyDetails?.policyDays.toString(),
          periodDays: policyDetails?.policyDays.toString(),
          underwritingYear: underwritingYear.toString(),
          sumInsured: generatePremiumDetails?.sumInsured.toString(),
          premium: generatePremiumDetails?.netPremium.toString(),
          state: "S",
          policyNumber: "",
          policyType: "1",
          status: "U",
          classCode: "6000",
          customerName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
          customerAddress: "",
          customerPhoneNumber: customerDetails?.PhoneNumber,
          premiumBasis: "SP",
          motorRiskStartDate: policyDetails?.startDate,
          motorRiskEndDate: policyDetails?.endDate,
          riskName: policyDetails?.riskType,
          motorRiskUnderwritingYear: underwritingYear.toString(),
          modelCode: vehicleDetails?.vehicleModel,
          modelName: vehicleDetails?.vehicleModelName,
          makeCode: vehicleDetails?.vehicleMake,
          makeName: vehicleDetails?.vehicleMakeName,
          usageCode: "1001",
          vehicleRegistrationNumber: vehicleDetails?.registrationNumber,
          bodyTypeCode: vehicleDetails?.bType,
          bodyType: vehicleDetails?.bName,
          manufactureYear: policyDetails?.vehicleYear,
          chassisNumber: vehicleDetails?.chassisNumber,
          cubicCapacity: generatePremiumDetails?.cubicCapacity.toString(),
          numberOfSeats: generatePremiumDetails?.passengers.toString(),
          excessApplicableYn: generatePremiumDetails?.buyBackExcess.toString(),
          extraTppdYn: tppdYn,
          vehicleValue: generatePremiumDetails?.vehicleValue.toString(),
          motorRiskCreatedBy: "",
          riskCode: generatePremiumDetails?.risk,
          color: vehicleDetails?.vehicleColor,
          tppdYn: tppdYn,
          tppdValue: generatePremiumDetails?.extraTppdAmount.toString(),
          reInsurancePremium: generatePremiumDetails?.riPremium.toString(),
          reInsuranceSumInsured: generatePremiumDetails?.riSumInsured.toString(),
          riskPremium: generatePremiumDetails?.riskPremium.toString(),
          fees: "0",
          registrationYear: policyDetails?.registrationYear,
          currencyRate:currencyRate.toString(),
          currency: currency,
          product: policyDetails?.policyType,
        },
        url: "/switching/process",
      });

      setUnderwriting(underwritingResponse.data.result);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUnderwritingRetry = async () => {
    setLoading(true);
    let uuid =  uuidv4();
    try {
      const underwritingResponse = await axios.post("/star", {
        requestId: uuid,
        serviceCode: serviceCode,
        method: "motorPolicyUnderwriting",
        async: true,
        params: {
          customerCode:  customerCode ? customerCode : customerCodeDetail?.customerCode,
          intermediaryCode: "GA00912",
          insuranceSource: "DIR",
          businessSource: "AGM",
          startDate: policyDetails?.startDate,
          endDate: policyDetails?.endDate,
          policyDays: policyDetails?.policyDays.toString(),
          periodDays: policyDetails?.policyDays.toString(),
          underwritingYear: underwritingYear.toString(),
          sumInsured: generatePremiumDetails?.sumInsured.toString(),
          premium: generatePremiumDetails?.netPremium.toString(),
          state: "S",
          policyNumber: "",
          policyType: "1",
          status: "U",
          classCode: "6000",
          customerName: `${customerDetails?.firstName} ${customerDetails?.lastName}`,
          customerAddress: "",
          customerPhoneNumber: customerDetails?.PhoneNumber,
          premiumBasis: "SP",
          motorRiskStartDate: policyDetails?.startDate,
          motorRiskEndDate: policyDetails?.endDate,
          riskName: policyDetails?.riskType,
          motorRiskUnderwritingYear: underwritingYear.toString(),
          modelCode: vehicleDetails?.vehicleModel,
          modelName: vehicleDetails?.vehicleModelName,
          makeCode: vehicleDetails?.vehicleMake,
          makeName: vehicleDetails?.vehicleMakeName,
          usageCode: "1001",
          vehicleRegistrationNumber: vehicleDetails?.registrationNumber,
          bodyTypeCode: vehicleDetails?.bType,
          bodyType: vehicleDetails?.bName,
          manufactureYear: policyDetails?.vehicleYear,
          chassisNumber: vehicleDetails?.chassisNumber,
          cubicCapacity: generatePremiumDetails?.cubicCapacity.toString(),
          numberOfSeats: generatePremiumDetails?.passengers.toString(),
          excessApplicableYn: generatePremiumDetails?.buyBackExcess.toString(),
          extraTppdYn: tppdYn,
          vehicleValue: generatePremiumDetails?.vehicleValue.toString(),
          motorRiskCreatedBy: "",
          riskCode: generatePremiumDetails?.risk,
          color: vehicleDetails?.vehicleColor,
          tppdYn: tppdYn,
          tppdValue: generatePremiumDetails?.extraTppdAmount.toString(),
          reInsurancePremium: generatePremiumDetails?.riPremium.toString(),
          reInsuranceSumInsured: generatePremiumDetails?.riSumInsured.toString(),
          riskPremium: generatePremiumDetails?.riskPremium.toString(),
          fees: "0",
          registrationYear: policyDetails?.registrationYear,
          currencyRate:currencyRate.toString(),
          currency: currency,
          product: policyDetails?.policyType,
        },
        url: "/switching/process",
      });

      setUnderwriting(underwritingResponse.data.result);
      setLoading(false);
    } catch (error) {

      setLoading(false);
    }
  };
  const handleCancel = () => {
    navigate(`/redirect/${serviceCode}/error`);
  };
  const handleBackClick = () => {
    navigate(-1);
  };

  const handleClick = () => {
    localStorage.clear();

    const paymentDetails = {
      paymentPin: underwritingDetails?.paymentPin,
    };

    const queryParams = new URLSearchParams(paymentDetails).toString();
    navigate(`/redirect/${serviceCode}/success?${queryParams}`);
  };
  const [isCopied, setIsCopied] = useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };


  const toggleDetailsVisibility = () => {
    setDetailsVisible(!isDetailsVisible);
  };
  return (
    <>
      {loading ? (
        <div className="jsonForm-loader">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="MotorInsurance">
          <div className="wizard">
            <div className="wizardComponent">
              <div className="CarDetails" style={{ color: "#000" }}>
              <FormattedMessage id="component.details"/>
              </div>
              <div className="Premium" style={{ color: "#000" }}>
              <FormattedMessage id="component.underwriting"/>
              </div>
              <div className="Policy" style={{ color: "#000" }}>
              <FormattedMessage id="component.getQuote"/>
              </div>
              <div className="Payment" style={{ color: "#000" }}>
              <FormattedMessage id="component.payment"/>
              </div>
              <div
                className="Rectangle1"
                style={{ background: "#40AD52" }}
              ></div>
              <div
                className="Rectangle2"
                style={{ background: "#40AD52" }}
              ></div>
              <div
                className="Rectangle3"
                style={{ background: "#40AD52" }}
              ></div>
              <div className="Ellipse">
                <div
                  className="EllipsePolicy"
                  style={{ background: "#40AD52" }}
                ></div>
                <div
                  className="EllipsePremium"
                  style={{ background: "#40AD52" }}
                ></div>
                <div
                  className="EllipsePayment"
                  style={{ background: "#40AD52" }}
                ></div>
                <div
                  className="EllipseCarDetails"
                  style={{ background: "#40AD52" }}
                ></div>
              </div>
            </div>
          </div>

          <div className="MotorInsuranceTitle">
            <h3><FormattedMessage id="component.payment"/></h3>
          </div>
          {(customer || customerCode) && underwriting ? (
            <>
              
              <div className="PayPremium">
                <div className="PayPremium-card">
                  <div>
                    <h5>{underwritingDetails?.policyNumber}</h5>
                    <h6><FormattedMessage id="component.policyNumber"/></h6>
                  </div>

                  <div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.paymentPin"/></p>
                      <div className="premium-col1-copy">
                       
                        <p className="premium-col2">
                          {underwritingDetails?.paymentPin
                            ? underwritingDetails?.paymentPin
                            : "N/A"}
                        </p>
                        <span>
                         
                          <CopyToClipboard
                            text={underwritingDetails?.paymentPin}
                            onCopy={onCopyText}
                          >
                            <span>
                              {isCopied ? (
                                "Copied!"
                              ) : (
                                <CopyOutlined className="Copy" />
                              )}
                            </span>
                          </CopyToClipboard>
                        </span>
                      </div>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.vehicleMake"/></p>
                      <p className="premium-col2">
                        {underwritingDetails?.motorRisks[0]?.makeName
                          ? underwritingDetails?.motorRisks[0]?.makeName
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.vehicleModel"/></p>
                      <p className="premium-col2">
                        {underwritingDetails?.motorRisks[0]?.modelName
                          ? underwritingDetails?.motorRisks[0]?.modelName
                          : "N/A"}
                      </p>
                    </div>
                    {isDetailsVisible && (
                      <>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.customerCode"/></p>
                      <p className="premium-col2">
                        {underwritingDetails?.customerCode
                          ? underwritingDetails?.customerCode
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.startDate"/></p>
                      <p className="premium-col2">
                        {date(underwritingDetails?.startDate)
                          ? date(underwritingDetails?.startDate)
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.endDate"/></p>
                      <p className="premium-col2">
                        {date(underwritingDetails?.endDate)
                          ? date(underwritingDetails?.endDate)
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.policyDays"/></p>
                      <p className="premium-col2">
                        {underwritingDetails?.policyDays
                          ? underwritingDetails?.policyDays
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.underwritingYear"/></p>
                      <p className="premium-col2">
                        {underwritingDetails?.underwritingYear
                          ? underwritingDetails?.underwritingYear
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.discount"/></p>
                      <p className="premium-col2">
                        {underwritingDetails?.discount
                          ? underwritingDetails?.discount
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.vehicleNumber"/></p>
                      <p className="premium-col2">
                        {underwritingDetails?.motorRisks[0]
                          ?.vehicleRegistrationNumber
                          ? underwritingDetails?.motorRisks[0]
                              ?.vehicleRegistrationNumber
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.manufactureYear"/></p>
                      <p className="premium-col2">
                        {underwritingDetails?.motorRisks[0]?.manufactureYear
                          ? underwritingDetails?.motorRisks[0]?.manufactureYear
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.tppd"/></p>
                      <p className="premium-col2">
                        {underwritingDetails?.motorRisks[0]?.tppdYn
                          ? underwritingDetails?.motorRisks[0]?.tppdYn
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.vehicleValue"/></p>
                      <p className="premium-col2">
                      <small>GHS</small> {numberWithCommas(underwritingDetails?.motorRisks[0]?.vehicleValue
                          ? underwritingDetails?.motorRisks[0]?.vehicleValue
                          : "N/A")}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.premium"/></p>
                      <p className="premium-col2">
                        <small>GHS</small> {numberWithCommas(underwritingDetails?.premium
                          ? underwritingDetails?.premium
                          : "N/A")}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.sumInsured"/></p>
                      <p className="premium-col2">
                      <small>GHS</small> {numberWithCommas(underwritingDetails?.sumInsured
                          ? underwritingDetails?.sumInsured
                          : "N/A")}
                      </p>
                    </div>
                    </>
                    )}

                  </div>
                  <div className="collaps-btn">
                     <button onClick={toggleDetailsVisibility}>
                        {isDetailsVisible ? <FaChevronCircleUp/> : <FaChevronCircleDown />}
                     </button>
                  </div>

                </div>
              </div>
              <div className="button">
                <div className="button-double">
                  <button className="button-gray" onClick={handleBackClick}>
                  <FormattedMessage id="component.button.back"/>
                  </button>
                  <button
                    className="button-green"
                    onClick={() => handleClick()}
                  >
                    <FormattedMessage id="component.button.pay"/>
                  </button>
                </div>
              </div>
            </>
          ) : (!customer && !customerCode)? (
            <div className="modal">
              <div className="modal-content">
                <h3><FormattedMessage id="component.PolicyCreationFailed"/></h3>
                <p>
                  <FormattedMessage id="component.PolicyCreationFailed.message"/>
                </p>
                <div>
                  <button
                    className="button-modal-green"
                    onClick={handleCustomerRetry}
                  >
                   <FormattedMessage id="component.button.retry"/>
                  </button>
                  <button className="button-modal-gray" onClick={handleCancel}>
                  <FormattedMessage id="component.button.cancel"/>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal">
              <div className="modal-content">
              <h3><FormattedMessage id="component.PolicyCreationFailed"/></h3>
                <p>
                <FormattedMessage id="component.PolicyCreationFailed.message"/>
                </p>
                <div>
                  <button
                    className="button-modal-green"
                    onClick={handleUnderwritingRetry}
                  >
                    <FormattedMessage id="component.button.retry"/>
                  </button>
                  <button className="button-modal-gray" onClick={handleCancel}>
                  <FormattedMessage id="component.button.cancel"/>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
