

import React, { useState, useEffect } from "react";
import { Form, Container, Card } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import LUPCD from "./LUPCD";
import { useParams } from "react-router-dom";
import { Vortex } from "react-loader-spinner";
import axios from "axios";
import "./LUP.css";

export default function LUP({ merchants }) {
  const params = useParams();
  const param = parseInt(params.serviceCode);

  const [refnum, setRefnum] = useState("");
  const [inputError, setInputError] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [show, setShow] = useState(false);
  const [lookUp, setLookUp] = useState([]);
  const [search, setSearch] = useState(false);

  const handleShow = async (e) => {
    try {
      e.preventDefault();
      setSearch(true);
      if (refnum === "") {
        setInputError("Please input a reference");
      } else {
        const dat = await axios
          .post(
            "core",
            {
              serviceCode: param,
              reference: refnum,
              verbose: true,
              url: "/billing/lookup",
              metaData: [
                {
                  key: "field1",
                  value: 1701187235,
                },
              ],
            },
            {
              auth: {
                username: "stanbic",
                password: "password",
              },
            }
          )
          .then((res) => {
            const merchantDetails = res.data;
            setLookUp(merchantDetails);
            console.log(merchantDetails, "this is the personal data");
          });
      }
      setSearch(false);
      setConfirmed(true);
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    handleShow();
  }, []);

  return (
    <div>
      <div className="LUP">
        <form className="LUP-form">
          <input
            type="text"
            placeholder="Enter Customer Number"
            className="LUP-input"
            value={refnum}
            onChange={(e) => setRefnum(e.target.value)}
          />

          <button
            className="LUP-button"
            disabled={!refnum}
            onClick={handleShow}
          >
            {search ? (
              <Vortex
                visible={true}
                height="40"
                width="40"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={["green", "green", "green", "green", "green", "green"]}
              />
            ) : (
              <BiSearch className="LUP-button-icon" />
            )}
          </button>
        </form>
      </div>
      {confirmed && (
        <LUPCD
          isConfirmed={setConfirmed}
          lookUp={lookUp}
          merchants={merchants}
          refnum={refnum}
        />
        
      )}
    </div>
  );
}
