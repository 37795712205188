import React, { useState, useEffect } from "react";
import CheckoutInput from "../CheckoutInput/CheckoutInput";
import "./CheckoutOptions.css";

const CheckoutOptions = ({ invoiceData, setCurrent }) => {
  const [next, setNext] = useState(false);
  const [momos, setMomos] = useState();

  const inputPage = (option) => {
    setNext(true);
    setMomos(option);
    setCurrent(1);
  };

  const types = invoiceData.payOptions;

  const listOptions = () =>
    types?.map((option, index) => (
      <button
        className="paymentOptions"
        onClick={() => inputPage(option)}
        key={index}
      >
        <div className="paymentOptions-img">
          <img src={option.logo} />
        </div>
        <div className="paymentOptions-text">
          <p className="font1">{option.name}</p>
          <p className="font2">{option.type.replaceAll("_", " ")}</p>
        </div>
      </button>
    ));

  return (
    <div>
      {next ? (
        <CheckoutInput
          invoiceData={invoiceData}
          momos={momos}
          setCurrent={setCurrent}
        />
      ) : (
        <>
          <div className="payment-options">{listOptions()}</div>
        </>
      )}
    </div>
  );
};

export default CheckoutOptions;
