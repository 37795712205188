
import React, { useEffect, useState } from "react";
import { Form, Container, Card } from "react-bootstrap";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { Vortex } from "react-loader-spinner";
import "./LUPACD.css";

export default function LUPACD({ lookUpp, merchants, refnum }) {
  let params = useParams();
  const navigate = useNavigate();
  const param = params.serviceCode;
  const [createInvoice, setCreateInvoice] = useState("");
  const [dropDown, setDropDown] = useState(false);
  const [arrowDown, setArrowDown] = useState(false);
  const [arrowUp, setArrowUp] = useState(false);
  const [amountDue, setAmountDue] = useState(lookUpp.balanceDue);
  const [searchLoader, setSearchLoader] = useState(false);

  const [amountError, setAmountError] = useState("");

  const down = () => {
    setDropDown(!dropDown);
    setArrowDown(!arrowDown);
    setArrowUp(!arrowUp);
  };

  const pay = async (e) => {
    try {
      e.preventDefault();
      setSearchLoader(true);
      if (amountDue === "") {
        setAmountError(" Please enter an amount");
        setSearchLoader(false);
      } else {
        const dat = await axios
          .post("pos", {
            requestId: "4d55391b-f463-4df7-8918-578d8eec94b5",
            username: "stanbic",
            password: "password",
            merchantOrderId: "202008241855",
            serviceCode: param,
            currency: "GHS",
            amount: amountDue,
            reference: refnum,
            url: "/webpos/createInvoice",
          })
          .then((res) => {
            const createInvoice = res.data.result;
            setCreateInvoice(createInvoice);
            // console.log(createInvoice, "createInvoice");
            const invoiceNum=createInvoice.invoiceNum
            navigate(`/checkout/${invoiceNum}`, {
              state: { merchant: merchants, invoice: createInvoice, refnum },
            });
          });
        setSearchLoader(false);
      }
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    pay();
  }, []);

  return (
    <div className="LUPACD">
      <div className="LUPACD-container">
        <div className="LUPACD-container-fold">
          <div className="LUPACD-fold-item">
            <p className="font1"> Customer Name</p>
            <p className="font2"> {lookUpp.customerName}</p>
          </div>
          <div className="LUPACD-fold-item1">
            <p className="font1"> Description</p>
            <p className="font2"> {lookUpp.description}</p>
          </div>
          {!arrowDown && (
            <BsChevronDown onClick={down} className="LUPACD-arrow" />
          )}
        </div>

        {dropDown && (
          <div className="LUPACD-container-fold">
            <div className="LUPACD-fold-item">
              <p className="font1"> Customer Segment</p>
              <p className="font2"> {lookUpp.customerSegment}</p>
            </div>
            <div className="LUPACD-fold-item1">
              <p className="font1"> Amount Due</p>
              <p className="font2"> {lookUpp.balanceDue}</p>
            </div>
            {arrowUp && <BsChevronUp onClick={down} className="LUPACD-arrow" />}
          </div>
        )}
      </div>

      <div className=" form">
        <div className="payflow-form-inpu">
          <input
            type="number"
            min="1"
            max="99999"
            placeholder="Amount"
            className="payflow-form-input"
            defaultValue={lookUpp.billTotal}
            value={amountDue}
            onChange={(e) => setAmountDue(e.target.value)}
          />
          <div className="payflow-form-input-error">
            {!amountDue && <span className="shake">{amountError} </span>}
          </div>
        </div>
        <div className="payflow-form-total ">
          <h6>Total : GHS {amountDue}</h6>
        </div>
      </div>

      <button className="payflow-form-button" onClick={pay}>
        Continue To Pay{" "}
        {searchLoader && (
          <Vortex
            visible={true}
            height="40"
            width="40"
            ariaLabel="vortex-loading"
            wrapperStyle={{}}
            wrapperClass="vortex-wrapper"
            colors={["white", "white", "white", "white", "white", "white"]}
          />
        )}
      </button>
    </div>
  );
}
