import React, { useEffect, useState } from "react";
import "./Premium.css";
import { date } from "../../../utils/dateformat";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import { Oval } from "react-loader-spinner";
import { useParams, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

const Premium = () => {
  const [premium, setPremium] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { serviceCode } = useParams();

const storedCustomerDetails = localStorage.getItem("customerDetails");
const customerDetails = JSON.parse(storedCustomerDetails);

const storedRecipientDetails = localStorage.getItem("recipientDetails");
const recipientDetails = JSON.parse(storedRecipientDetails);

const storedPolicyDetails = localStorage.getItem("policyDetails");
const policyDetails = JSON.parse(storedPolicyDetails);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let uuid =  uuidv4()
      try {
        const premiumResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "motorPremiumGeneration",
          async: true,
          params: {
            customerName: recipientDetails?.buyForSomeone
            ? `${recipientDetails?.recipientFirstName} ${recipientDetails?.recipientLastName}`
            : `${customerDetails?.firstName} ${customerDetails?.lastName}`,
          customerPhoneNumber: recipientDetails?.buyForSomeone
            ? recipientDetails?.recipientNumber
            : customerDetails?.phoneNumber,
          customerEmail: recipientDetails?.buyForSomeone
            ? recipientDetails?.recipientEmail
            : customerDetails?.email,
            risk: policyDetails?.riskType,
            product:policyDetails?.policyType,
            vehicleValue: policyDetails?.vehicleValue,
            passengers: policyDetails?.nOP,
            cubicCapacity: policyDetails?.cubicCapacity ? policyDetails?.cubicCapacity.toString() : (parseFloat(policyDetails?.liter) * 1000).toString(),
            yearOfManufacture: policyDetails?.vehicleYear,
            registrationYear: policyDetails?.registrationYear,
            extraTppd: policyDetails?.isDamageLimitEnabled.toString(),
            extraTppdAmount: policyDetails?.amount.toString(),
            buyBackExcess: policyDetails?.buyBackExcessEnabled.toString(),
            startDate: policyDetails?.startDate,
            endDate: policyDetails?.endDate,
          },
          url: "/switching/process",
        });

        setPremium(premiumResponse.data.result);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const premiumData = premium?.data?.vehicles[0];

  const handlePremiumRetry = async () => {
    setLoading(true);
    let uuid =  uuidv4()
    try {
        const premiumResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "motorPremiumGeneration",
          async: true,
          params: {
            customerName: recipientDetails?.buyForSomeone
            ? `${recipientDetails?.recipientFirstName} ${recipientDetails?.recipientLastName}`
            : `${customerDetails?.firstName} ${customerDetails?.lastName}`,
          customerPhoneNumber: recipientDetails?.buyForSomeone
            ? recipientDetails?.recipientNumber
            : customerDetails?.phoneNumber,
          customerEmail: recipientDetails?.buyForSomeone
            ? recipientDetails?.recipientEmail
            : customerDetails?.email,
            risk: policyDetails?.riskType,
            product:policyDetails?.policyType,
            vehicleValue: policyDetails?.vehicleValue,
            passengers: policyDetails?.nOP,
            cubicCapacity: policyDetails?.cubicCapacity.toString(),
            yearOfManufacture: policyDetails?.vehicleYear,
            registrationYear: policyDetails?.registrationYear,
            extraTppd: policyDetails?.isDamageLimitEnabled.toString(),
            extraTppdAmount: policyDetails?.amount.toString(),
            buyBackExcess: policyDetails?.buyBackExcessEnabled.toString(),
            startDate: policyDetails?.startDate,
            endDate: policyDetails?.endDate,
          },
          url: "/switching/process",
        });

        setPremium(premiumResponse.data.result);
      setLoading(false);
    } catch (error) {

      setLoading(false);
    }
  };

  const handleClick = () => {
    const premiumDetails={
      premium:premium
    }
    localStorage.setItem("premiumDetails", JSON.stringify(premiumDetails))
    navigate(`/vehicle/${serviceCode}/vehicleDetails`);
  };
  const handleBackClick = () => {
    navigate(-1); 
  };
  const handleCancel =()=>{
    navigate(`/vehicle/${serviceCode}/error`);
  }

  const numberWithCommas = (number) => {
    return number.toLocaleString(); 
  };
  return (
    <>
      {loading ? (
        <div className="jsonForm-loader">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="MotorInsurance">

<div className="wizard">
              <div className="wizardComponent">
                <div className="CarDetails"style={{color: '#000'}}><FormattedMessage id="component.details"/></div>
                <div className="Premium"> <FormattedMessage id="component.underwriting"/></div>
                <div className="Policy"style={{color: '#000'}}><FormattedMessage id="component.getQuote"/></div>
                <div className="Payment"><FormattedMessage id="component.payment"/></div>
                <div className="Rectangle1"style={{background: '#40AD52'}}></div>
                <div className="Rectangle2"></div>
                <div className="Rectangle3"></div>
                <div className="Ellipse">
                  <div className="EllipsePolicy"style={{background: '#40AD52'}}></div>
                  <div className="EllipsePremium"></div>
                  <div className="EllipsePayment"></div>
                  <div className="EllipseCarDetails" style={{background: '#40AD52'}}></div>
                </div>
              </div>
            </div>

          <div className="MotorInsuranceTitle">
            <h3><FormattedMessage id="component.getQuote"/></h3>
          </div>
          {premiumData ? (
            <>
              <div className="premium">
                <div className="premium-card">
                  <div>
                    <h5>{policyDetails?.policyName}</h5>
                    <h4><small>GHS</small> {numberWithCommas(premium?.data?.TotalPremium)}</h4>
                  </div>
                  <div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.vehicleValue"/></p>
                      <p className="premium-col2">
                      <small>GHS</small> {numberWithCommas(premiumData?.vehicleValue
                          ? premiumData?.vehicleValue
                          : "N/A")}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1">
                      <FormattedMessage id="component.thirdPartyDamageIncrease"/>
                      </p>
                      <p className="premium-col2">
                        {premiumData?.extraTppd ? "Yes" : "No"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.nOP"/></p>
                      <p className="premium-col2">
                        {premiumData?.passengers
                          ? premiumData?.passengers
                          : "N/A"}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.startDate"/></p>
                      <p className="premium-col2">
                        {date(premiumData?.startDate?premiumData?.startDate:premiumData?.starDate)}
                      </p>
                    </div>
                    <div className="premium-row">
                      <p className="premium-col1"><FormattedMessage id="component.endDate"/></p>
                      <p className="premium-col2">
                        {date(premiumData?.endDate)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button">
                <div className="button-double">
                  <button className="button-gray" onClick={handleBackClick}>
                  <FormattedMessage id="component.button.back"/>
                  </button>
                  <button
                    className="button-green"
                    onClick={() => handleClick()}
                  >
                    <FormattedMessage id="component.button.next"/>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="modal">
              <div className="modal-content">
                <h3><FormattedMessage id="component.premiumCreationFailed"/></h3>
                <p>
                <FormattedMessage id="component.premiumCreationFailed.message"/>
                </p>
                <div>
                  <button
                    className="button-modal-green"
                    onClick={handlePremiumRetry}
                  >
                    <FormattedMessage id="component.button.retry"/>
                  </button>
                  <button className="button-modal-gray" onClick={handleCancel}>
                    <FormattedMessage id="component.button.cancel"/>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Premium;
