import React, { Component } from "react";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container">
          <div className="text-center mb-5">
            <h1 className="font-weight-bold mb-4">
              Sorry, something went wrong
            </h1>
            <Button
              type="primary"
              onClick={() => {
                useNavigate(-1);
              }}
            >
              Back
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
