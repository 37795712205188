import React from "react";
import "./CheckoutInput.css";

import Card from "./InputTypes/Card/Card";
import Bank from "./InputTypes/Bank/Bank";
import MobileMoney from "./InputTypes/MobileMoney/MobileMoney";

const CheckoutInput = ({ invoiceData, momos, setCurrent }) => {
  let paytypes = momos.type;
  const codee = () => {
    switch (paytypes) {
      case "MOBILE_MONEY":
        return (
          <MobileMoney
            momos={momos}
            invoiceData={invoiceData}
            setCurrent={setCurrent} 
          />
        );

      case "CARD":
        return (
          <Card momos={momos}  invoiceData={invoiceData} setCurrent={setCurrent} />
        );

      case "BANK":
        return (
          <Bank momos={momos} invoiceData={invoiceData}  />
        );
    }
  };

  return <div>{codee()}</div>;
};

export default CheckoutInput;
